/**
 * Format number
 * @param {} number
 */
export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function changeAlias(string) {
  let str = string;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");//eslint-disable-line
  str = str.replace(/ + /g, " ");
  str = str.trim();
  str = str.replace(/ /g, '-');;
  return str;
}

// Ham them dau "," vao tien (vidu: 1000000 => 1,000,000)
export function numberWithCommas(x, text = '.') {
  x = String(x);
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, `$1${text}$2`);
  return x;
}

export function renderMoney(x, text = ',') {
  try {
    x = String(x);
    var length = x.length
    var cut = 0
    var inr = ''
    for (let index = 0; index < x.length; index++) {
      var count = index + 1
      if (count > 3 && (length - 3) <= 3) { inr = 'k'; cut = 3; break; }
      if (count > 6 && (length - 6) <= 3) { inr = 'tr'; cut = 6; break; }
      if (count > 9 && (length - 9) <= 3) { inr = 'tỷ'; cut = 9; break; }
    }
    var result = x.slice(0, (x.length - cut)) + "" + inr;
    if (cut === 6) { result = result + "" + x.slice(x.length - cut, (x.length - cut + 3)) }
    if (cut === 9) { result = result + "" + x.slice(x.length - cut, (x.length - cut + 3)) + "tr" }
    return result;
  } catch (error) {

  }

}

