import React from 'react';
import {Row, Col, Card} from 'antd'
import './dashboard.css'
import {connect} from 'react-redux'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <Row gutter={24}>
                <Col className="gutter-row" lg={24}>
                    {/* <Card>
                        CONTENT
                    </Card> */}
                </Col>
            </Row>
        );
    }
}

export default connect()(Dashboard)
