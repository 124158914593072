export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const TOGGLE_COLLAPSED = 'TOGGLE_COLLAPSED'

export const CURRENT_KEY_MENU = 'CURRENT_KEY_MENU'

export async function connectSocket(payload) {
    return {
        type: CONNECT_SOCKET,
        data: payload
    }
}

export async function toggleCollapsed() {
    return {
        type: TOGGLE_COLLAPSED
    }
}


export async function setCurrentKeyMenu(current_key) {
    return {
        type: CURRENT_KEY_MENU,
        key: current_key
    }
}
