export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_OUT = 'LOGIN_OUT';
export const CHANGE_COLLAPSE = 'CHANGE_COLLAPSE';
export const SAVE_PERMISSION = 'SAVE_PERMISSION'

export async function loginSuccess(userInfo) {
    return {
        type: LOGIN_SUCCESS,
        data: userInfo
    }
}
export async function logoutRequest() {
    return {
        type: LOGIN_OUT,
    }
}

export function changeCollapse(collapse) {
    return {
        type: CHANGE_COLLAPSE,
        collapse: !collapse
    }
}

export async function savePermission(payload) {
    return {
        type: SAVE_PERMISSION,
        data: payload
    }
}
