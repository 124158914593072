import React from 'react';
import { FormOutlined, PlusOutlined, RollbackOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    Card,
    Input,
    Button,
    Select,
    Modal,
    message,
    Form,
    Upload,
    Row, Col, Table
} from 'antd';

import callApi from "../../utils/callApi";
import './addTable.css'


const { Option } = Select;
const { TextArea } = Input;

const layoutForm = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    wrapperCol: { offset: 7, span: 10 },
};



function validateRequire(value) {
    if (value && value !== "") {
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }
    return {
        validateStatus: 'error',
        errorMsg: 'Trường này là bắt buộc!',
    };
}

class EditTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requireForm: {},
            id: this.props.match.params.id || null,
            loadingTable: true,
            dataTable: {
                theme: "default",
                chart_type: "line"
            },
            listField: [],
            addNewTable: true,
            listTypes: null,
            isModalVisible: false,
            newFieldName: "",
            newFieldValue: "",
            listTableFields: null,
        };
    }



    getDataInApi = async (data) => {
        await this.setState({
            loadingTable: true
        })
        const dataTable = await callApi(`v1/table/` + data.id, 'get', {});
        if (dataTable && dataTable.status) {
            var product = dataTable.data;
            this.setState({ addNewTable: false })
            this.setState({ dataTable: product, listField: product.list_field })

        }
    }

    callApiGetTableField = async (data = {}) => {
        const dataTable = await callApi(`v1/table/get-table-field`, 'get', {});
        if (dataTable && dataTable.status) {
            var product = dataTable.data;
            this.setState({ listTableFields: product })

        }
    }


    async componentDidMount() {
        // Query New
        const { id } = this.state;
        if (id) { await this.getDataInApi({ id: id }) }
        await this.callApiGetTableField()
    }

    handleChangeSelectTable = (name) => {
        const { dataTable } = this.state;
        return async function (value) {
            dataTable[name] = value;
            await this.setState({ dataTable: dataTable });
        }.bind(this);
    }

    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }


    addProduct = async () => {
        var dataUpdate = this.validateField()
        let { listField } = this.state
        dataUpdate.table_field = listField;
        if (dataUpdate) {
            const dataTable = await callApi(`v1/table`, 'POST', dataUpdate);
            if (dataTable && dataTable.code) {
                message.success(dataTable.msg);
                return
            }

            message.error(dataTable.msg);
        }
    }

    updateProduct = async (status = false) => {
        var dataUpdate = this.validateField()
        if (dataUpdate) {
            let { listField } = this.state
            dataUpdate.table_field = listField;
            const resultUpdate = await callApi(`v1/table/${this.state.id}`, 'PUT', dataUpdate);
            if (resultUpdate && resultUpdate.code) {
                message.success(resultUpdate.msg);
                this.props.history.goBack();
                return
            }
            message.error(resultUpdate.msg);
            return
        }
    }


    validateField = () => {
        var { name, code, theme, chart_type } = this.state.dataTable
        var dataResult = this.state.dataTable;
        if (!name || !code || theme == "" || !chart_type) {
            message.error("Bạn phải điền đầy đủ các trường bắt buộc")
            return false
        }
        return dataResult;
    }


    handleRequire = async event => {
        const { name, value } = event.target;
        const { dataTable } = this.state;
        dataTable[name] = value;
        await this.setState({
            dataTable: dataTable,
            requireForm: {
                ...this.state.requireForm,
                [name]: validateRequire(value)
            }
        });
    };

    handleCancelModal = () => {
        this.setState({ isModalVisible: false })
    }

    handleAddColumn = () => {
        let { newFieldName, newFieldValue, listField } = this.state
        listField = [...listField, {
            field_name: newFieldName,
            field_value: newFieldValue
        }]
        this.setState({ listField: listField, newFieldName: "", newFieldValue: "" })
        this.handleCancelModal()
    }

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    removeField = async (index) => {
        let { listField } = this.state
        let list = listField;
        list.splice(index, 1);
        listField = [...list]
        this.setState({ listField: listField })
    }

    render() {
        const { dataTable, requireForm, addNewTable, listTableFields, listTypes, listField, isModalVisible, newFieldName, newFieldValue } = this.state;
        // Ban hien thi danh sach cac order
        const columnsTable = [
            {
                title: 'Tên hiển thị',
                align: "center",
                width: '35%',
                key: "field_name",
                render: (text, record, index) => {
                    return (
                        <div>{record.field_name || ""}</div>
                    )
                },
            },
            {
                title: 'Mapping field',
                align: "center",
                width: '35%',
                key: "field_value",
                render: (text, record, index) => {
                    return (
                        <div>{record.field_value || ""}</div>
                    )
                },
            },
            {
                title: 'Thao tác',
                align: "center",
                width: '30%',
                key: "action",
                render: (text, record, index) => {
                    return (
                        <div> <Button type="primary" size="small" shape="circle" onClick={() => { this.removeField(index) }} icon={<DeleteOutlined />} danger></Button></div>
                    )
                },
            },
        ]

        return (
            <Card className="edit-order" >
                <div className="form">
                    <Form {...layoutForm} name="basic">

                        <Form.Item label="Tên bảng:" required
                            validateStatus={requireForm.name ? requireForm.name.validateStatus : null}
                            help={requireForm.name ? requireForm.name.errorMsg : null}>
                            <Input value={dataTable.name || ""} name="name" onChange={this.handleRequire} />
                        </Form.Item>

                        <Form.Item label="Mã bảng:" required
                            validateStatus={requireForm.code ? requireForm.code.validateStatus : null}
                            help={requireForm.code ? requireForm.code.errorMsg : null}>
                            <Input value={dataTable.code || ""} name="code" onChange={this.handleRequire} />
                        </Form.Item>

                        <Form.Item label="Giao diện:" required
                            validateStatus={requireForm.theme ? requireForm.theme.validateStatus : null}
                            help={requireForm.theme ? requireForm.theme.errorMsg : null}>
                            <Select value={(dataTable.theme).toString() || "1"} name="theme" onChange={this.handleChangeSelectTable('theme').bind(this)} >
                                <Option value="default" >Mặc định</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Loại chart:" required
                            validateStatus={requireForm.chart_type ? requireForm.theme.validateStatus : null}
                            help={requireForm.chart_type ? requireForm.theme.errorMsg : null}>
                            <Select value={dataTable.chart_type || "line"} name="chart_type" onChange={this.handleChangeSelectTable('chart_type').bind(this)} >
                                <Option value="line" >Line</Option>
                                <Option value="cand" >Candlestick</Option>
                            </Select>
                        </Form.Item>


                        <Form.Item label="Đầu bảng:"
                            validateStatus={requireForm.header ? requireForm.header.validateStatus : null}
                            help={requireForm.header ? requireForm.header.errorMsg : null}>
                            <TextArea rows={4} value={dataTable.header || ""} name="header" onChange={this.handleRequire} />
                        </Form.Item>

                        <Form.Item label="Chân bảng:"
                            validateStatus={requireForm.footer ? requireForm.footer.validateStatus : null}
                            help={requireForm.footer ? requireForm.footer.errorMsg : null}>
                            <TextArea rows={4} value={dataTable.footer || ""} name="footer" onChange={this.handleRequire} />
                        </Form.Item>

                        <Row>
                            <Col offset={6} span={12} >
                                <div className="typeProduct">
                                    <Button type="primary" size='small' onClick={() => { this.setState({ isModalVisible: true }) }} > Thêm cột </Button>
                                    <Table rowKey="field_value" columns={columnsTable} dataSource={listField} size="small" pagination={false}></Table>
                                </div>
                            </Col>
                        </Row>


                        <Form.Item {...tailLayout}>
                            {addNewTable ? (
                                <div className="disInlineBlock">
                                    <Button type="primary" onClick={(e) => this.addProduct()} icon={<FormOutlined />}> Thêm mới </Button>
                                </div>
                            ) : (
                                <div className="disInlineBlock">
                                    <Button type="primary" onClick={(e) => this.updateProduct()} icon={<FormOutlined />}> Lưu lại </Button>
                                </div>
                            )}
                            <span style={{ width: 50, display: "inline-block" }}></span>
                            <Button type="danger" ghost onClick={() => { this.props.history.goBack() }} icon={<RollbackOutlined />}> Quay lại </Button>

                        </Form.Item>
                    </Form>
                </div>

                <Modal
                    title="Thêm mới cột"
                    visible={isModalVisible}
                    onOk={this.handleAddColumn}
                    onCancel={this.handleCancelModal}
                    okText="Thêm mới"
                    cancelText="Đóng"
                    width={550}
                >
                    <div style={{ "marginBottom": "10px" }}>
                        <span> Tên cột hiển thị: </span>
                        <Input value={newFieldName} name="newFieldName" onChange={this.handleChangeInput} placeholder="Nhập tên cột" />
                    </div>
                    <div>
                        <span> Mapping Field: </span>
                        <Select value={newFieldValue} name="newFieldValue" placeholder="Chọn trạng thái" className="selectTableField" onChange={this.handleChangeSelect('newFieldValue')}  >
                            {listTableFields && listTableFields.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                        </Select>
                    </div>

                </Modal>

            </Card>
        );
    }
}



export default EditTable;