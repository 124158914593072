import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, Layout, Avatar, Dropdown } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import './Header.css';
import { logoutRequest, changeCollapse } from '../../pages/user/UserAction'
import { connect } from 'react-redux';

import Cookies from 'js-cookie'
import { folder_cp } from '../../config/config';



class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
        };
    }

    handleSignOut = async () => {
        this.props.dispatch(await logoutRequest());
        Cookies.remove('token')
        window.location.href = `${folder_cp}/login`;
    }


    changeCollapse = () => {
        this.props.onCollapseChange();
    }

    componentDidUpdate() { }

    render() {
        const {
            collapsed,
        } = this.props;

        const { username, avatar } = this.props.user || {}


        const menu = (
            <Menu key='menu' style={{ width: 100 }}>
                <Menu.Item key='SignOut' onClick={this.handleSignOut} >
                    Đăng xuất
                </Menu.Item>
            </Menu>
        );
        const rightContent = [
            <Dropdown overlay={menu} key='user'>
                <div>
                    <span style={{ color: '#999', marginRight: 4 }}>Hi, </span>
                    <span>{username || ""}</span>
                    <Avatar style={{ marginLeft: 8, marginRight: 20 }} src={avatar} />
                </div>
            </Dropdown>,
        ];

        return (
            <Layout.Header
                className={
                    !collapsed
                        ? 'Header__header Header__fixed ant-layout-header'
                        : 'Header__header Header__fixed Header__collapsed ant-layout-header'
                }
                id='layoutHeader'
            >
                <div className='Header__button' onClick={this.changeCollapse}>
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </div>
                <div className='Header__rightContainer'>{rightContent}</div>
            </Layout.Header>
        );
    }
}

Header.propTypes = {
    fixed: PropTypes.bool,
    user: PropTypes.object,
    menus: PropTypes.array,
    collapsed: PropTypes.bool,
    onSignOut: PropTypes.func,
    notifications: PropTypes.array,
    onCollapseChange: PropTypes.func,
    onAllNotificationsRead: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        user: state.user.info
    }
}

export default connect(mapStateToProps)(Header);
