import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, FilterOutlined, UpSquareOutlined, PlusCircleOutlined, RedoOutlined, CloseOutlined, CheckOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';

import { Card, Table, Row, Col, Input, Popconfirm, Pagination, Button, message, Select, DatePicker, Modal } from 'antd';
import callApi from "../../utils/callApi";
import moment from 'moment'
import { folder_cp } from '../../config/config'
import { connect } from 'react-redux'
import './listScan.css'

const { RangePicker } = DatePicker;
const { Option } = Select;
class ListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            row_per_page: 20,
            keyword_in: "name",
            keyword: "",
            status: [],
            cat_id: null,
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: "",
            loadingTable: true,
            dataProduct: null,


        };
    }

    // Tai du lieu tu api
    getDataInApi = async (data) => {
        await this.setState({
            loadingTable: true
        })
        const dataFilter = await callApi('v1/table', 'get', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            return this.setState({ dataProduct: dataFilter.data, loadingTable: false })
        }
    }

    callApiUpdateCat = async (id, data) => {
        const dataFilter = await callApi(`v1/categories/${id}`, 'put', data);
        if (dataFilter && dataFilter.status) {
            this.refreshData();
            return;
        }
        message.error(dataFilter.msg);
    }



    // Chon kieu keyword trong filter
    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name" >Tên</Option>
            </Select>
        )
    }

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    // Reset lai filter
    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: "",
            status: [],
            cat_id: null,
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: ""
        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({
            page: 1
        })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            cat_id: this.state.cat_id,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create
        }
        await this.getDataInApi(dataQuery)
    }

    // Lam moi lai du lieu
    refreshData = async () => {
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            cat_id: this.state.cat_id,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create
        }
        await this.getDataInApi(dataQuery)
    }



    async componentDidMount() {
        // Query New
        const queryApi = { page: 1, row_per_page: this.state.row_per_page };
        await this.getDataInApi(queryApi)
    }

    // Paginate
    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            cat_id: this.state.cat_id,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
        }
        this.getDataInApi(dataQuery)
    }



    // In ra status
    renderStatus = (status) => {
        switch (status) {
            case 0:
                return "Đã xoá"
            case 1:
                return "Hoạt động"
            default:
                return status
        }
    }
    renderIconStatus = (status) => {
        switch (status) {
            case 0:
                return <PlusCircleOutlined />
            case 1:
                return <CheckOutlined />
            case 2:
                return <CloseOutlined />
            default:
                return <></>
        }
    }





    handleDeleteLog = async (record, status) => {
        this.callApiUpdateCat(record.id, { status: status })
    }


    render() {
        const { keyword, status, page, row_per_page, dataProduct, loadingTable } = this.state;


        // Ban hien thi danh sach cac order
        const columnsTable = [
            {
                title: 'Tên mục',
                align: "center",
                width: '25%',
                key: "cat_name",
                render: (text, record, index) => {
                    return (
                        <div>{record.name || ""}</div>
                    )
                },
            },
            {
                title: 'Mã bảng',
                width: '20%',
                key: "code_table",
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div>[{record.code || ""}]</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                width: '20%',
                key: "cat_tatus",
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div>{this.renderStatus(record.status)}</div>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                width: '20%',
                key: "thong_tin_qua",
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div>{record.create_time ? moment.unix(record.create_time).format('H:m DD/MM/YYYY ') : ""}</div>
                    );
                },
            },


            {
                title: 'Thao tác',
                key: "action",
                width: '25%',
                align: "center",
                render: (text, record, index) => {


                    return (
                        <div>

                            <Link to={folder_cp + "/setup-table/" + record.id} >
                                <Button type="primary" size="small" shape="circle" className="export-button" icon={<UpSquareOutlined />}></Button>
                            </Link>

                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            <Link to={folder_cp + "/edit-table/" + record.id} >
                                <Button type="primary" size="small" shape="circle" className="export-button" icon={<EditOutlined />}></Button>
                            </Link>
                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            {record.status ? (
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có chắc muốn xoá thư mục này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 0)}
                                    okText="Xác nhận"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<DeleteOutlined />} danger></Button>
                                </Popconfirm>
                            ) : (<>
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có muốn khôi phục lại thư mục này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 1)}
                                    okText="Khôi phục"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<UndoOutlined />}></Button>
                                </Popconfirm>

                            </>)}

                        </div>
                    )
                }
            }
        ];

        return (
            <div className="order-list">
                {/* ORDER - HEADER */}
                <Card loading={dataProduct ? false : true}>
                    <Row gutter={24} style={{ marginLeft: 12 }}>
                        <Col span={18} >
                            <Row gutter={24}>
                                <Col span={12} className='colItem'>
                                    <div className="filter-item">
                                        <div className="filter-lable">
                                            Từ khóa:
                                        </div>
                                        <div className="filter-input">
                                            <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                        </div>
                                    </div>
                                </Col>


                                <Col span={12} className='colItem'>
                                    <div className="filter-item">
                                        <div className="filter-lable">
                                            Trạng thái:
                                        </div>
                                        <div className="filter-input">
                                            <Select value={status} name="status" placeholder="Chọn trạng thái" className="select-status" onChange={this.handleChangeSelect('status')} mode="multiple" >
                                                <Option value="1">Hoạt động</Option>
                                                <Option value="0">Đã xóa</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={6} className='colItem'>
                            <div className="filter-item filter-action">
                                <Button onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row>  {/* End Filter */}
                </Card>

                {/* ORDER - BODY */}
                <Card >
                    {dataProduct ? (
                        <div className="order-body">
                            <Row span={24} className="head-table">
                                <Col span={12}>
                                    <span style={{ width: 10, display: "inline-block" }}></span>
                                    <Link to={folder_cp + "/add-table"} >
                                        <Button type="primary" size="small" className="export-button" icon={<PlusCircleOutlined />}>Thêm bảng</Button>
                                    </Link>

                                    <span style={{ width: 15, display: "inline-block" }}></span>
                                    <span> Tổng số <b>{dataProduct.total}</b> bản ghi </span>


                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataProduct.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                </Col>
                            </Row>
                            {/*</Row>*/}

                            <Table loading={loadingTable} rowKey="id" columns={columnsTable} dataSource={dataProduct.docs} size="small" pagination={false} />
                            <div style={{ paddingTop: '10px' }} />

                            <div style={{ textAlign: 'right' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataProduct.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />

                            </div>


                        </div>
                    ) : (
                        <Card loading={true}> </Card>
                    )}

                </Card>



            </div>
        );
    }
}


export default connect()(ListTable);