import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd';
import ScrollBar from '../ScrollBar'
import * as config from '../../config/config'
import SiderMenu from './Menu'

// import styles from './Sider.less'
import './Sider.css'

class Sider extends PureComponent {

    render() {
        const {
            menus,
            theme,
            isMobile,
            collapsed,
            location,
            //onCollapseChange,
        } = this.props;
        
        return (
            <Layout.Sider
                width={256}
                theme={theme}
                breakpoint="lg"
                trigger={null}
                collapsible
                collapsed={collapsed}
                //onBreakpoint={isMobile ? null : onCollapseChange}
                className="Sider__sider ant-layout-sider ant-layout-sider-light"
            >
                <div className="Sider__brand">
                    <div className="Sider__logo">

                        {collapsed ? <img style={{width: "25px"}} alt="logo" src={config.logoMiniPath}/> :
                            <img style={{width: "150px"}} alt="logo" src={config.logoPath}/>}
                    </div>
                </div>

                <div className="Sider__menuContainer">
                    <ScrollBar
                        options={{
                            // Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
                            suppressScrollX: true,
                        }}
                    >
                        <SiderMenu
                            location={location}
                            menus={menus}
                            theme={theme}
                            isMobile={isMobile}
                            collapsed={collapsed}
                            //onCollapseChange={onCollapseChange}
                        />
                    </ScrollBar>
                </div>
            </Layout.Sider>
        )
    }
}

Sider.propTypes = {
    menus: PropTypes.array,
    theme: PropTypes.string,
    isMobile: PropTypes.bool,
    collapsed: PropTypes.bool,
    onThemeChange: PropTypes.func,
    onCollapseChange: PropTypes.func,
}

export default Sider;
