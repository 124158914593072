import React from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, ExportOutlined, RetweetOutlined, UndoOutlined, EditOutlined, FilterOutlined, DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { Card, Table, Row, Col, Input, Popconfirm, Pagination, Checkbox, Button, message, Select, DatePicker, Modal, Switch } from 'antd';

import callApi from "../../utils/callApi";
import moment from 'moment'
import { numberWithCommas } from '../../utils/common'
import './ListUser.css'
import { folder_cp } from '../../config/config'
import { lte, mean } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;

class ListUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            row_per_page: 20,
            keyword_in: "",
            keyword: "",
            visibleModal: false,
            visibleModalType: false,
            filterStatus: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: "",
            listStatus: [],
            dataTable: null,
            loadingTable: true,
            server_time: null,
            userRole: null,
            selectedRole: null,
            roles: null,
            userSetRole: null

        };
    }

    // Tai du lieu tu api
    getDataInApi = async (data) => {
        await this.setState({
            loadingTable: true
        })
        const dataFilter = await callApi('v1/user', 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ dataTable: dataFilter.data, loadingTable: false, server_time: dataFilter.current_time })
        }
    }

    callApiGetRole = async (user_id, data = {}) => {
        const dataFilter = await callApi(`v1/user/${user_id}/role`, 'get', data);
        if (dataFilter && dataFilter.status) {
            const useRole = [];
            const selectedRole = [];
            dataFilter.data.map(item => {
                useRole.push({
                    label: item.name, value: item.id
                })
                if (item.is_selected) {
                    selectedRole.push(item.id)
                }
            })
            return this.setState({ userRole: useRole, selectedRole: selectedRole })
        }
    }

    callApiSetRoleUser = async (user_id, data = {}) => {
        const dataFilter = await callApi(`v1/user/${user_id}/role`, 'POST', data);
        console.log(dataFilter);
        if (dataFilter && dataFilter.status) {
            message.success("Thành công");
            this.handleCancel();
            this.refreshData();
            return
        }
        message.error("Có lỗi")
    }

    callApiGetPermission = async (data = {}) => {
        const dataFilter = await callApi(`v1/role`, 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ roles: dataFilter.data })
        }
    }



    // Chon kieu keyword trong filter
    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name">Tên</Option>
                <Option value="id">ID</Option>
                <Option value="code">Mã vàng</Option>
            </Select>
        )
    }

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }


    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    handleChangeSelectPaymentMethod = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
            this.handleFilter()

        }.bind(this);
    }



    handleChangePickDate = async (data) => {
        var start_time_create = data[0] ? data[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix() : null;
        var end_time_create = data[1] ? data[1].set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).unix() : null;
        await this.setState({
            dateFilter: data,
            start_time_create: start_time_create,
            end_time_create: end_time_create
        })
    }

    // Reset lai filter
    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: "",
            filterStatus: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: ""
        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({
            page: 1
        })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            is_delete: this.state.filterStatus,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,

        }
        this.getDataInApi(dataQuery)
    }

    // 
    async componentDidMount() {
        // Query New
        const queryApi = { page: 1, row_per_page: 20 };
        await this.getDataInApi(queryApi)

    }

    // Paginate
    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
        }
        this.getDataInApi(dataQuery)
    }



    // Lam moi lai du lieu
    refreshData = async () => {
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create
        }

        await this.getDataInApi(dataQuery)

    }

    // Hien thi Model
    handleCancel = () => {
        this.setState({
            visibleModal: false,
            visibleModalType: false
        });
    };

    // Hien thi Model
    handleOpenModalSetRole = async (record) => {
        await this.callApiGetRole(record.id);
        this.setState({ visibleModal: true, userSetRole: record });
    };

    onChangeCheckbox = (checkedValues) => {
        this.setState({ selectedRole: checkedValues })
    }

    handleUpdateRole = async () => {
        const { selectedRole, userSetRole } = this.state;
        this.callApiSetRoleUser(userSetRole.id, { role_ids: selectedRole })
    }

    callApiUpdateUser = async (id, dataUpdate) => {
        const response = await callApi(`v1/user/${id}`, 'PUT', dataUpdate);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }


    handleDeleteLog = async (record, status) => {
        await this.callApiUpdateUser(record.id, { is_delete: status })
        this.refreshData();
    }


    render() { // too long line
        const { selectedRole, userRole, listStatus, filterStatus, keyword, dataTable, loadingTable, server_time, row_per_page, page, visibleModal, visibleModalType } = this.state;
        // Ban hien thi danh sach cac order
        const columnsTable = [
            {
                title: 'ID',
                align: "center",
                width: '5%',
                key: "type_code",
                render: (text, record, index) => {
                    return (
                        <div>
                            {record.id || ""}
                        </div>
                    )
                }
            },
            {
                title: 'Tài khoản',
                align: "center",
                width: '20%',
                key: "name",
                render: (text, record, index) => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            Họ tên:  {record.fullname || ""} <br />
                            Tài khoản: {record.username || ""}
                        </div>
                    )
                }
            },


            {
                title: 'Loại tài khoản',
                align: "center",
                width: '20%',
                key: "type",
                render: (text, record, index) => {
                    const { is_admin } = record;
                    return (
                        <div style={{ textAlign: "left" }}>
                            {is_admin ? "Admin" : "Thường"}
                        </div >
                    )
                }
            },
            {
                title: 'Danh sách role',
                align: "center",
                width: '20%',
                key: "role",
                render: (text, record, index) => {
                    const { is_admin } = record;
                    return (
                        <div >
                            <div style={{ textAlign: "left", marginBottom: 10 }}>
                                {record.roles.map((item, index) => {
                                    return (
                                        <span key={index} className='roleSelected'>{item.name} </span>
                                    )
                                })}
                            </div>
                            <Button type="primary" size="small" onClick={() => this.handleOpenModalSetRole(record)}> Sét quyền</Button>
                        </div >
                    )
                }
            },
            {
                title: 'Thao tác',
                align: "center",
                width: '20%',
                key: "action",
                render: (text, record, index) => {
                    return (
                        <div>
                            <Link to={folder_cp + "/add-type-user/" + record.id} >
                                <Button type="primary" size="small" shape="circle" className="export-button" icon={<RetweetOutlined />}></Button>
                            </Link>
                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            <Link to={folder_cp + "/edit-user/" + record.id} >
                                <Button type="primary" size="small" shape="circle" className="export-button" icon={<EditOutlined />}></Button>
                            </Link>
                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            {!record.is_delete ? (
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có chắc muốn xoá thư mục này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 1)}
                                    okText="Xác nhận"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<DeleteOutlined />} danger></Button>
                                </Popconfirm>
                            ) : (<>
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có muốn khôi phục lại thư mục này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 0)}
                                    okText="Khôi phục"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<UndoOutlined />}></Button>
                                </Popconfirm>

                            </>)}
                        </div>
                    )
                }
            },
        ];

        return (
            <div className="order-list">
                {/* ORDER - HEADER */}
                <Card loading={dataTable ? false : true}>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Từ khóa:
                                </div>
                                <div className="filter-input">
                                    <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Trạng thái:
                                </div>
                                <div className="filter-input">
                                    <Select value={filterStatus} name="status" placeholder="Chọn trạng thái" className="select-status" onChange={this.handleChangeSelect('filterStatus')} mode="multiple" >
                                        <Option key={1} value={1}>Đang hoạt động</Option>
                                        <Option key={2} value={0}>Đã xoá</Option>
                                    </Select>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="filter-item">
                                <Button className="filter-button" onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />} style={{ marginLeft: 10 }}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row> {/* End Filter */}
                </Card>

                {/* ORDER - BODY */}
                <Card >

                    {dataTable ? (
                        <>
                            <Row span={24} className="head-table">
                                <Col span={12}>
                                    <span> Tổng số <b>{dataTable.total}</b> bản ghi </span>
                                    <Link to={folder_cp + "/create-user"}>
                                        <Button type="primary" size='small'>Thêm mới</Button>
                                    </Link>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>

                                    <div style={{ textAlign: 'right' }}>
                                        <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                    </div>
                                </Col>
                            </Row>
                            <Table loading={loadingTable} rowKey="id" columns={columnsTable} dataSource={dataTable.docs} size="small" pagination={false} />
                            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                            </div>
                        </>

                    ) : (
                        <Card loading={true}> </Card>
                    )}
                </Card>


                <Modal
                    title={'Chọn mã vàng quản lý'}
                    visible={visibleModalType}
                    onCancel={this.handleCancel}
                    footer={null}
                    className="manual-modal">
                    <div>

                    </div>
                </Modal>

                <Modal
                    title={'Chọn quyền'}
                    visible={visibleModal}
                    onCancel={this.handleCancel}
                    footer={null}
                    className="manual-modal">
                    <div>
                        {userRole ? (
                            <>
                                <div className='listRoles'>
                                    <Checkbox.Group options={userRole} defaultValue={selectedRole} onChange={this.onChangeCheckbox} />
                                </div>
                                <div style={{ textAlign: "center", marginTop: 20 }}>
                                    <Button type="primary" size="small" onClick={() => this.handleUpdateRole()}> Cập nhật </Button>
                                </div>
                            </>
                        ) : null}

                    </div>
                </Modal>


            </div>
        );
    }
}



export default ListUser;