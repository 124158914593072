import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Card, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'
import { pathMatchRegexp, queryAncestors } from '../../utils'
import './Bread.css'

class Bread extends PureComponent {
    generateBreadcrumbs = paths => {
        return paths.map((item, key) => {
            const content = item && (
                <Fragment>
                    {item.name}
                </Fragment>
            )

            return (
                item && (
                    <Breadcrumb.Item key={key}>
                        {paths.length - 1 !== key ? (
                            <Link to={item.path || '#'}>{content}</Link>
                        ) : (
                                content
                            )}
                    </Breadcrumb.Item>
                )
            )
        })
    }

    render() {
        const { routeList, location } = this.props
        const currentRoute = routeList.find(
            _ => _.path && pathMatchRegexp(_.path, location.pathname)
        )

        const paths = currentRoute ? queryAncestors(routeList, currentRoute, 'breadcrumbParentId').reverse() : [routeList[0], { id: 404, name: `Not Found` },]
        return (
            <Card className="bread-body">
                <Breadcrumb className="Bread__bread" style={{marginBottom:"0"}}>{this.generateBreadcrumbs(paths)}</Breadcrumb>
                <span className="ant-page-header-heading-title" title="Search Table">{paths[paths.length - 1].title}</span>
            </Card>
        )
    }
}

Bread.propTypes = {
    routeList: PropTypes.array,
}

export default Bread
