import React from 'react';
import { Link } from 'react-router-dom';

import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import {
    Card,
    Table,
    Row,
    Col,
    Input,
    Popconfirm,
    Pagination,
    Button,
    message,
    Select,
    Modal,
    Switch,
    DatePicker,
} from 'antd';
import callApi from "../../utils/callApi";
import moment from 'moment'



const { RangePicker } = DatePicker;
const { Option } = Select;
class SupplierCmd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataTable: null,
            visibleModal: false,
            error_msg: "",
            page: 1,
            row_per_page: 20,
            keyword_in: "",
            keyword: "",
            type: "gold",
            supplier_code: this.props.match.params.supplier_code || null,

        };
    }

    // Tai du lieu tu api
    callApiGetSupplierCmd = async (data = {}) => {
        await this.setState({
            loading: true
        })
        const dataFilter = await callApi('v1/supplier-cmd', 'get', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            return this.setState({ dataTable: dataFilter.data, loading: false })
        }
    }

    callApiUpadteCrawlUrl = async (data) => {
        const dataFilter = await callApi('v1/supplier/url/update-crawl', 'PUT', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            message.success('Thành công', 3);
            this.refreshData();
            return;
        }
        message.error(dataFilter.msg, 3);
    }

    refreshData = async () => {
        var dataQuery = {
            type: this.state.type,
            supplier_code: this.state.supplier_code
        }

        await this.callApiGetSupplierCmd(dataQuery)
    }

    handleCancel = () => {
        this.setState({
            visibleModal: false
        })
    }

    showModalError = (record) => {
        this.setState({
            visibleModal: true,
            error_msg: record.error_msg
        })
    }

    handleChangeCrawl = async (record) => {
        let { is_crawl, cmd } = record;
        let update = {
            is_crawl: Number(!Boolean(is_crawl)),
            url_cmd: cmd
        }
        this.callApiUpadteCrawlUrl(update);
    }




    async componentDidMount() {
        // Query New
        await this.callApiGetSupplierCmd({
            type: this.state.type,
            supplier_code: this.state.supplier_code
        })
    }

    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name">Tên</Option>
                <Option value="id">ID</Option>
                <Option value="code">Mã vàng</Option>
            </Select>
        )
    }


    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: ""

        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({ page: 1 })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            type: this.state.type,
            supplier_code: this.state.supplier_code
        }
        this.callApiGetSupplierCmd(dataQuery)
    }

    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            type: this.state.type,
            supplier_code: this.state.supplier_code
        }
        this.callApiGetSupplierCmd(dataQuery)
    }

    handleSetType(type) {
        this.setState({ type: type });
        this.handleFilter()
    }


    render() {
        const { loading, dataTable, error_msg, type, visibleModal, keyword, filterStatus, row_per_page, page } = this.state
        const columnsTable = [
            {
                title: 'ID',
                width: '5%',
                key: "ID",
                render: (text, record, index) => {
                    return (
                        <div> {record.id} </div>
                    )
                }
            },
            {
                title: 'Tên',
                width: '30%',
                key: "name",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div className='title'> {record.name} </div>
                        </div>
                    )
                }
            },
            {
                title: 'Link lấy dữ liệu',
                width: '30%',
                key: "name",
                render: (text, record, index) => {
                    return (
                        <div>
                            <a href={record.crawl_url} target="_blank">
                                <Button type="dashed" size="small"> Truy cập </Button>
                            </a>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                align: "center",
                width: '20%',
                key: "status",
                render: (text, record, index) => {
                    return (
                        <div>
                            <span key={"statusRequest " + record.is_error ? "error" : "active"}>
                                {record.is_error ? (
                                    <div>
                                        <div style={{ marginBottom: 10 }}> Thời gian lỗi: {moment.unix(record.error_time).format('H:m DD/MM/YYYY ')} </div>
                                        <Button danger size='small' onClick={this.showModalError.bind(this, record)}>Chi tiết lỗi</Button>
                                    </div>

                                ) : (
                                    <div>Đang hoạt động</div>
                                )}
                            </span>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái crawl',
                align: "center",
                width: '15%',
                key: "crawl",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div style={{ marginBottom: 10 }}> <Switch checked={record.is_crawl} onChange={this.handleChangeCrawl.bind(this, record)} /> </div>
                        </div>
                    )
                }
            },


        ];
        return (
            <div className="order-list">
                <Card loading={loading}>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Từ khóa:
                                </div>
                                <div className="filter-input">
                                    <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Trạng thái:
                                </div>
                                <div className="filter-input">
                                    <Select value={filterStatus} name="status" placeholder="Chọn trạng thái" className="select-status" onChange={this.handleChangeSelect('filterStatus')} mode="multiple" >
                                        <Option key={1} value={1}>Đang hoạt động</Option>
                                        <Option key={2} value={0}>Đã xoá</Option>
                                    </Select>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="filter-item">
                                <Button className="filter-button" onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />} style={{ marginLeft: 10 }}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row> {/* End Filter */}
                </Card>
                <Card loading={loading}>
                    {dataTable && (
                        <>
                            <div style={{ marginBottom: 20 }}>
                                <Button style={{ marginRight: 10 }} type={(type == "gold" ? "primary" : "dashed")} onClick={() => this.handleSetType("gold")}> Giá vàng </Button>
                                <Button type={(type == "save_money" ? "primary" : "dashed")} onClick={() => this.handleSetType("save_money")}> Lãi suất tiết kiệm </Button>
                            </div>

                            <Row span={24} className="head-table" style={{ marginBottom: 10 }}>
                                <Col span={12}>
                                    <span> Tổng số <b>{dataTable.total}</b> bản ghi </span>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                    </div>
                                </Col>
                            </Row>

                            <Table loading={loading} rowKey="id" columns={columnsTable} dataSource={dataTable.docs} size="small" pagination={false} />
                            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                            </div>
                            <Modal
                                title={'Chi tiết lỗi'}
                                visible={visibleModal}
                                onCancel={this.handleCancel}
                                footer={null}
                                className="manual-modal">
                                <div>
                                    {error_msg}
                                </div>
                            </Modal>
                        </>
                    )}

                </Card>
            </div>
        );
    }
}


export default SupplierCmd;