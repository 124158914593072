import axios from 'axios'
import config, { API_URL, ADMIN_URL_PHP } from '../config/config'
import { message } from "antd";
import Cookies from 'js-cookie'
import { folder_cp } from '../config/config'

export default async function callApi(endpoint, method = 'get', body ) {
    try {
        let token = Cookies.get('token');
        var dataQuery = { data: body }
        if (method === 'get' || method === "GET") {
            // Set param query on url
            dataQuery = { params: body }
        }

        const response = await axios({
            method: method,
            url: `${API_URL}/${endpoint}`,
            ...dataQuery,
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })


        return response.data
    } catch (err) {
        // let { status } = err.response || {};
        // if (status == 403) {
        //     Cookies.remove('token')
        //     window.location.href = folder_cp + '/login';
        // }
        // message.error('Có lỗi trong quá trình xử lý', 3);
        return false;
    }

}
