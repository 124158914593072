import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom'
import {
    arrayToTree,
} from '../../utils'
import { connect } from "react-redux";

const { SubMenu } = Menu;

class SiderMenu extends PureComponent {

    generateMenus = (data) => {

        return data.map(item => {
            const { permission, children } = item
            const checkPermissions = (children || []).map(v => this.checkPermission(v.permission))
            if (item.children && (checkPermissions || []).includes(true)) {
                return (
                    <SubMenu
                        key={`parent_` + item.id}
                        icon={item.icon}
                        title={item.name}
                    >
                        {this.generateMenus(item.children)}
                    </SubMenu>
                );
            }

            return this.checkPermission(permission) && (
                <Menu.Item key={item.id} >
                    <NavLink to={item.url ? item.url : (item.path ? item.path : '#')}>
                        {item.icon}
                        <span>{item.name}</span>
                    </NavLink>
                </Menu.Item>
            );
        });
    }

    checkPermission(permission) {
        const permissions = this.props.permissions || [];
        const user = this.props.user || {};

        return Boolean(permissions.includes(permission) || !permission || user.is_admin);
    }


    render() {
        const {
            theme,
            //isMobile,
            //user
        } = this.props
        let { menus } = this.props;
        // Generating tree-structured data for menu content.
        menus = menus.filter(_ => _.isMenu);
        const menuTree = arrayToTree(menus, 'id', 'menuParentId')
        return (
            <Menu mode="inline" theme={theme}>
                {this.generateMenus(menuTree)}
            </Menu>
        )
    }
}

SiderMenu.propTypes = {
    menus: PropTypes.array,
    theme: PropTypes.string,
    isMobile: PropTypes.bool,
}


const mapStateToProps = (state) => {
    return {
        user: state.user.info,
        permissions: state.user.permissions,
    }
}
export default connect(mapStateToProps)(SiderMenu)
