import React, { Component } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import ReactDOM from 'react-dom';
import './login.css'
import { connect } from 'react-redux'
import { loginSuccess, logoutRequest } from "./UserAction";
import { Route, Redirect } from 'react-router'
import _ from 'lodash'
import callApi from "../../utils/callApi";
import { message } from "antd/lib/index";
import Cookies from 'js-cookie'
import { API_URL, folder_cp } from '../../config/config';
import axios from 'axios'

class UserLogin extends Component {

    handleSubmit = async (values) => {
        const userInfo = await callApi('v1/user/login', 'post', values);
        if (userInfo && userInfo.status) {
            var dataUser = userInfo.data.user || null;
            this.props.dispatch(await loginSuccess(dataUser))
            Cookies.set('token', (userInfo.data.token || ""))
            message.success('Đăng nhập thành công!', 3);
            return;
        }
        message.error(userInfo.msg, 3);
    };


    async componentDidMount() {
        let token = Cookies.get('token');
        if (_.isEmpty(token)) {
            this.props.dispatch(await logoutRequest())
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.user != nextProps.user) {
            if (!_.isEmpty(nextProps.user)) {
                setTimeout(() => {
                    window.location.href = folder_cp + "/";
                }, 1)

            }
        }
    }

    render() {
        return (
            <div id="form-login">
                <img src="/logo.png" width="222" />

                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.handleSubmit}>
                    <Form.Item
                        name="username"
                        rules={[{
                            required: true,
                            message: 'Please input your Username!',
                        }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Tài khoản" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{
                            required: true,
                            message: 'Please input your Password!'
                        }]}>
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Mật khẩu" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button"> Đăng nhập </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.info
    }
}

export default connect(mapStateToProps)(UserLogin);
