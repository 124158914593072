import React from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, ExportOutlined, UndoOutlined, EditOutlined, FilterOutlined, DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { Card, Table, Row, Col, Input, Popconfirm, Pagination, Button, message, Select, DatePicker, Modal, Switch } from 'antd';

import callApi from "../../utils/callApi";
import moment from 'moment'
import { numberWithCommas } from '../../utils/common'

import { folder_cp } from '../../config/config'
import { lte } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;


class ListRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            row_per_page: 20,
            keyword_in: "",
            keyword: "",

            filterStatus: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: "",
            listStatus: [],
            dataTable: null,
            loadingTable: true,
            server_time: null,

        };
    }

    // Tai du lieu tu api
    getDataInApi = async (data) => {
        await this.setState({
            loadingTable: true
        })
        const dataFilter = await callApi('v1/role', 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ dataTable: dataFilter.data, loadingTable: false, server_time: dataFilter.current_time })
        }
    }



    // Chon kieu keyword trong filter
    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name">Tên</Option>
                <Option value="id">ID</Option>
                <Option value="code">Mã vàng</Option>
            </Select>
        )
    }

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }


    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    handleChangeSelectPaymentMethod = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
            this.handleFilter()

        }.bind(this);
    }



    handleChangePickDate = async (data) => {
        var start_time_create = data[0] ? data[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix() : null;
        var end_time_create = data[1] ? data[1].set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).unix() : null;
        await this.setState({
            dateFilter: data,
            start_time_create: start_time_create,
            end_time_create: end_time_create
        })
    }

    // Reset lai filter
    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: "",
            filterStatus: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: ""
        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({
            page: 1
        })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            is_delete: this.state.filterStatus,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,

        }
        this.getDataInApi(dataQuery)


    }

    // 
    async componentDidMount() {
        // Query New
        const queryApi = { page: 1, row_per_page: 20 };
        await this.getDataInApi(queryApi)

    }

    // Paginate
    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
        }
        this.getDataInApi(dataQuery)
    }

    callApiUpdateRole = async (id, dataUpdate) => {
        const response = await callApi(`v1/role/${id}`, 'PUT', dataUpdate);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    // Lam moi lai du lieu
    refreshData = async () => {
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create
        }

        await this.getDataInApi(dataQuery)

    }

    handleDeleteLog = async (record, status) => {
        await this.callApiUpdateRole(record.id, { is_delete: status })
        this.refreshData();
    }


    render() { // too long line
        const { listStatus, filterStatus, keyword, dataTable, loadingTable, server_time, row_per_page, page } = this.state;


        // Ban hien thi danh sach cac order
        const columnsTable = [
            {
                title: 'ID',
                align: "center",
                width: '5%',
                key: "type_code",
                render: (text, record, index) => {
                    return (
                        <div>
                            {record.id || ""}
                        </div>
                    )
                }
            },
            {
                title: 'Tên role',
                align: "center",
                width: '20%',
                key: "name",
                render: (text, record, index) => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            {record.name || ""}
                        </div>
                    )
                }
            },
            {
                title: 'Mô tả',
                align: "center",
                width: '20%',
                key: "type",
                render: (text, record, index) => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            {record.description || ""}
                        </div>
                    )
                }
            },
            {
                title: 'Thao tác',
                align: "center",
                width: '10%',
                key: "action",
                render: (text, record, index) => {
                    return (
                        <div>

                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            <Link to={folder_cp + "/edit-role/" + record.id} >
                                <Button type="primary" size="small" shape="circle" className="export-button" icon={<EditOutlined />}></Button>
                            </Link>

                            <div style={{ "display": "inline-block", "width": "10px" }}></div>
                            {!record.is_delete ? (
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có chắc muốn xoá nhóm quyền này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 1)}
                                    okText="Xác nhận"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<DeleteOutlined />} danger></Button>
                                </Popconfirm>
                            ) : (<>
                                <Popconfirm
                                    placement="topRight"
                                    title="Bạn có muốn khôi phục lại nhóm quyền này?"
                                    onConfirm={this.handleDeleteLog.bind(this, record, 0)}
                                    okText="Khôi phục"
                                    cancelText="Huỷ"
                                >
                                    <Button type="primary" size="small" shape="circle" icon={<UndoOutlined />}></Button>
                                </Popconfirm>

                            </>)}
                        </div>
                    )
                }
            },
        ];

        return (
            <div className="order-list">
                {/* ORDER - HEADER */}
                <Card loading={dataTable ? false : true}>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Từ khóa:
                                </div>
                                <div className="filter-input">
                                    <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Trạng thái:
                                </div>
                                <div className="filter-input">
                                    <Select value={filterStatus} name="status" placeholder="Chọn trạng thái" className="select-status" onChange={this.handleChangeSelect('filterStatus')} mode="multiple" >
                                        <Option key={1} value={1}>Đang hoạt động</Option>
                                        <Option key={2} value={0}>Đã xoá</Option>
                                    </Select>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="filter-item">
                                <Button className="filter-button" onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />} style={{ marginLeft: 10 }}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row> {/* End Filter */}
                </Card>

                {/* ORDER - BODY */}
                <Card >
                    {dataTable ? (
                        <>
                            <Row span={24} className="head-table" style={{ marginBottom: 10 }}>
                                <Col span={12}>
                                    <span> Tổng số <b>{dataTable.total}</b> bản ghi </span>
                                    <Link to={folder_cp + "/create-role"}>
                                        <Button type="primary" size='small'>Thêm mới</Button>
                                    </Link>
                                </Col>

                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                    </div>
                                </Col>
                            </Row>
                            <Table loading={loadingTable} rowKey="id" columns={columnsTable} dataSource={dataTable.docs} size="small" pagination={false} />
                            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                            </div>
                        </>

                    ) : (
                        <Card loading={true}> </Card>
                    )}
                </Card>


            </div>
        );
    }
}



export default ListRole;