import React from 'react';
import { BrowserRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import routes from './routes';
import withTracker from './withTracker';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';
import withClearCache from "./ClearCache";
import routesPublic from './routesPublic';
import LoadBase from "./components/LoadBase/loadBase";

const PrvLayout = withRouter((props) => <PrivateLayout {...props} />);
const PubLayout = withRouter((props) => <PublicLayout {...props} />);
 
const PrivateRoutes = () => {
    return (
        <Switch>
            <Route exact path={routesPublic.map(item => item.path)}>
                <PubLayout>
                    {routesPublic.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={withTracker((props) => {
                                    return (
                                        <route.component {...props} />
                                    );
                                })}
                            />
                        );
                    })}
                </PubLayout>
            </Route>
            <Route path={routes.map(item => item.path)}>
                <PrvLayout>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={withTracker((props) => {
                                    return (
                                        <route.component {...props} />
                                    );
                                })}
                            />
                        );
                    })}
                </PrvLayout>
            </Route>
        </Switch>
    );
};

const MainApp = () => {
    return (
        <>
            <LoadBase />
            <Router basename={process.env.REACT_APP_BASENAME || ''}>
                <PrivateRoutes />
            </Router>
        </>
    );
};

const ClearCacheComponent = withClearCache(MainApp);
function App() {
    return <ClearCacheComponent />;
}


export default App;
