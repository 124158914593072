import React from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, ExportOutlined, FileDoneOutlined, EditOutlined, FilterOutlined, PrinterOutlined, RedoOutlined } from '@ant-design/icons';
import { Card, Table, Row, Col, Input, Popconfirm, Pagination, Button, message, Select, DatePicker, Modal, Switch } from 'antd';

import callApi from "../../utils/callApi";
import moment from 'moment'
import { numberWithCommas } from '../../utils/common'
import ComponentPrintAddress from './print/print-address'
import ModelExport from './model/modelExportExcel'
import './order.css'
import { folder_cp } from '../../config/config'
import { lte } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;


class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            row_per_page: 20,
            keyword_in: "",
            keyword: "",
            status: [],
            filterSupplier: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: "",
            loadingTable: true,
            dataGold: null,
            selectedRowKeys: [],
            id_order: [],
            visible: false,
            confirmLoading: false,
            rowmodal: null,
            statusModal: "",
            noteModal: "",
            disabledModel: true,
            dataPrint: null,
            listSupplier: [],
            visibleManual: false,
            manualTypeCode: null,
            dataExport: [],
            dataUpdateManual: {},
            visibleExport: false,
            loadingExport: false,
            server_time: null,
            visibleEditType: false,
            typeIdSelected: null,
            newNameType: null,
            typeStatus: null

        };
    }

    // Tai du lieu tu api
    getDataInApi = async (data) => {
        await this.setState({
            loadingTable: true
        })
        const dataFilter = await callApi('v1/type', 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ dataGold: dataFilter.data, loadingTable: false, server_time: dataFilter.current_time })
        }
    }

    callApiUpdateType = async (type_id, update_data) => {
        const dataUpdate = await callApi(`v1/type/${type_id}`, 'PUT', update_data);
        if (dataUpdate && dataUpdate.status) {
            message.success('Thành công', 3);
            this.refreshData()
            this.handleCancel();
        }
    }

    callApiSetPriceManual = async (type_code, update_data) => {
        const dataUpdate = await callApi(`v1/type/${type_code}/manual`, 'PUT', update_data);
        if (dataUpdate && dataUpdate.status) {
            message.success('Thành công', 3);
            this.refreshData();
            this.handleCancel();
            return;
        }
        message.error(dataUpdate.msg, 3);
    }

    // Tai du lieu tu api
    getDataExport = async (data) => {
        await this.setState({ loadingExport: true });
        const dataExport = await callApi('v1/spoint/export-order', 'get', data);
        await this.setState({ loadingExport: false });
        if (dataExport && dataExport.status) {
            return this.setState({ dataExport: dataExport.data })
        }
    }

    callApiSupplier = async (data = {}) => {
        const dataFilter = await callApi('v1/supplier', 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ listSupplier: dataFilter.data.docs })
        }
    }

    // Chon kieu keyword trong filter
    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name">Tên</Option>
                <Option value="id">ID</Option>
                <Option value="code">Mã vàng</Option>
            </Select>
        )
    }

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    // Ham duoc goi khi filter
    handleChangeInputPriceManual = async (event) => {
        let { dataUpdateManual } = this.state
        const { name, value } = event.target;
        dataUpdateManual[name] = value;
        await this.setState({ dataUpdateManual });
    }

    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }


    handleChangeSelectPaymentMethod = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
            this.handleFilter()

        }.bind(this);
    }



    handleChangePickDate = async (data) => {
        var start_time_create = data[0] ? data[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix() : null;
        var end_time_create = data[1] ? data[1].set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).unix() : null;
        await this.setState({
            dateFilter: data,
            start_time_create: start_time_create,
            end_time_create: end_time_create
        })
    }

    // Reset lai filter
    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: "",
            filterSupplier: [],
            dateFilter: [null, null],
            start_time_create: "",
            end_time_create: ""
        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({
            page: 1
        })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            supplier_code: this.state.filterSupplier,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
            status: this.state.typeStatus,
        }
        this.getDataInApi(dataQuery)


    }

    // 
    async componentDidMount() {
        // Query New
        const queryApi = { page: 1, row_per_page: 20, status: this.state.typeStatus };
        await this.getDataInApi(queryApi)
        await this.callApiSupplier({ page: 1, row_per_page: 1000 })

    }

    // Paginate
    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
            status: this.state.typeStatus,
        }
        this.getDataInApi(dataQuery)
    }



    // Lam moi lai du lieu
    refreshData = async () => {
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create,
            status: this.state.typeStatus,
        }

        await this.getDataInApi(dataQuery)

    }

    // Hien thi Model
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleExport: false,
            visibleManual: false,
            visibleEditType: false
        });
    };



    showModalExport = async () => {
        var dataQuery = {
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            status: this.state.status,
            dateFilter: this.state.dateFilter,
            start_time_create: this.state.start_time_create,
            end_time_create: this.state.end_time_create
        }

        await this.getDataExport(dataQuery)
        await this.setState({
            visibleExport: true,
        });
    };


    handleChangeUpdateType = async (record, field) => {
        let data = {};
        data[field] = (Number(record[field]) == 1 ? 0 : 1);
        this.callApiUpdateType(record.id, data);
    }

    openPopupSetPriceManual = async (record) => {
        this.setState({
            manualTypeCode: record.code,
            dataUpdateManual: record.last_crawler || {},
            visibleManual: true
        })
    }

    handleSavePriceManual = async () => {
        let { dataUpdateManual, manualTypeCode } = this.state;
        let { sell, buy } = dataUpdateManual;
        let dataUpdate = {
            sell: Number(sell),
            buy: Number(buy)
        }
        this.callApiSetPriceManual(manualTypeCode, dataUpdate);
    }


    genTime = (current_date) => {
        let { server_time } = this.state
        let distance = (server_time - current_date) * 1000;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        return (<span >
            {days > 0 && (<span> {days}d </span>)}
            {hours > 0 && (<span> {hours}h </span>)}
            {minutes > 0 && (<span> {minutes}m </span>)}
        </span>)
    }

    openPopupEditType = async (record) => {
        this.setState({
            typeIdSelected: record.id,
            newNameType: record.title,
            visibleEditType: true
        })
    }

    handleUpdateType = async () => {
        let { newNameType, typeIdSelected } = this.state;
        let dataUpdate = {
            title: newNameType
        }
        this.callApiUpdateType(typeIdSelected, dataUpdate);
    }

    handleChangeStatus = (value) => {
        this.setState({ typeStatus: value });
    }



    render() { // too long line
        const { listSupplier, visibleEditType, newNameType, typeStatus, server_time, dataUpdateManual, visibleManual, filterSupplier, dataExport, visibleExport, loadingExport, keyword, dateFilter, page, row_per_page, dataGold, loadingTable, selectedRowKeys, visible, confirmLoading, rowmodal, statusModal, noteModal, disabledModel, dataPrint } = this.state;

        let dataTable = []
        if (dataGold) {
            dataTable = [ ...(dataGold.docs || [])]
        }
        if (typeStatus && dataGold) {
            if (typeStatus == "online") {
                dataTable = (dataGold.docs || []).filter(item=> item.crawler_time != null)
            }else{
                dataTable = (dataGold.docs || []).filter(item=> item.crawler_time == null)
            }
        }


        // Ban hien thi danh sach cac order
        const columnsTable = [
            {
                title: 'Mã vàng',
                align: "center",
                width: '10%',
                key: "type_code",
                render: (text, record, index) => {
                    const { crawler_time } = record;
                    const clsStatus = crawler_time ? "online" : "offline";
                    const titleStatus = crawler_time ? "Online" : "Offline";
                    return (
                        <div style={{ textAlign: "left" }}>
                            <span className={clsStatus} title={titleStatus}></span>
                            {record.code || ""}
                        </div>
                    )
                }
            },
            {
                title: 'Tên vàng',
                width: '25%',
                key: "type_name",
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div >
                            <div>  {record.title || ""} </div>
                            <Button type='link' size='small' onClick={() => this.openPopupEditType(record)} icon={<EditOutlined />}></Button>

                        </div>
                    );
                }
            },
            {
                title: 'Nhà cung cấp',
                width: '20%',
                key: "supplier",
                align: "center",
                render: (text, record, index) => <span > {record.supplier_info.title || ""} </span>
            },
            {
                title: 'Tình trạng crawler',
                width: '20%',
                key: "crawler",
                align: "center",
                render: (text, record, index) => {
                    let last_crawler = record.last_crawler || false;
                    return (
                        <div >
                            {last_crawler && (
                                <div className="marignBottom5">
                                    <span className="marignBottom5"> <strong>Mua:</strong>   {numberWithCommas(last_crawler.buy || "")} </span> -
                                    <span className="marignBottom5"> <strong>Bán:</strong>   {numberWithCommas(last_crawler.sell || "")} </span><br />
                                    <span > <strong>Update:</strong> {this.genTime(last_crawler.update_time)} trước</span>
                                </div>
                            )}
                            <span> Crawler: <Switch defaultChecked={record.is_crawl} onChange={this.handleChangeUpdateType.bind(this, record, 'is_crawl')} /> </span>
                        </div>
                    )
                }
            },
            {
                title: 'Cài đặt giá vàng',
                key: "setting_manual",
                width: '15%',
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div style={{ marginBottom: 10 }} >  <Button size='small' onClick={() => this.openPopupSetPriceManual(record)}> Set giá </Button> </div>
                        </div>
                    )
                }
            }
        ];

        return (
            <div className="order-list">
                {/* ORDER - HEADER */}
                <Card loading={dataGold ? false : true}>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Từ khóa:
                                </div>
                                <div className="filter-input">
                                    <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row gutter={24} className="filter">
                        <Col span={12}>
                            <div className="filter-item">
                                <div className="filter-lable">
                                    Nhà cung cấp:
                                </div>
                                <div className="filter-input">
                                    <Select value={filterSupplier} name="status" placeholder="Chọn nhà cung cấp" className="select-status" onChange={this.handleChangeSelect('filterSupplier')} mode="multiple" >
                                        {listSupplier.map((sup, index) => <Option key={index} value={sup.code}>{sup.title}</Option>)}
                                    </Select>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="filter-item">
                                <Button className="filter-button" onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />} style={{ marginLeft: 10 }}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row> {/* End Filter */}
                </Card>

                {/* ORDER - BODY */}
                <Card >
                    {dataGold ? (
                        <div className="order-body">

                            <Row span={24} className="head-table">
                                <Col span={12}>

                                    <span> Tổng số <b>{dataGold.total}</b> bản ghi </span>

                                    <div style={{ "display": "inline-block", "width": "10px" }}></div>
                                    <Button type={!typeStatus?"default":"dashed"} size="small" onClick={() => this.handleChangeStatus(null)}>  <span>Tất cả</span> </Button>

                                    <div style={{ "display": "inline-block", "width": "10px" }}></div>
                                    <Button type={typeStatus == "online"?"default":"dashed"}  size="small" onClick={() => this.handleChangeStatus("online")}>  <span className="online" title="Online"></span>Online </Button>

                                    <div style={{ "display": "inline-block", "width": "10px" }}></div>
                                    <Button type={typeStatus == "offline"?"default":"dashed"} size="small" onClick={() => this.handleChangeStatus("offline")}>  <span className="offline" title="Offline"></span>Offline </Button>


                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataGold.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                </Col>
                            </Row>

                            {/* Get Componet Print */}
                            <div style={{ display: "none" }} >
                                {/* In 1 */}
                                {dataPrint ? (<div ref={el => (this.componentRefOne = el)}>
                                    {/* <ComponentToPrint data={dataPrint} />
                                    <div className="page-break" /> */}
                                    <ComponentPrintAddress data={dataPrint} />
                                </div>) : (<div></div>)}


                            </div>

                            <Table loading={loadingTable} rowKey="id" columns={columnsTable} dataSource={dataTable} size="small" pagination={false} />
                            <div style={{ paddingTop: '10px' }} />

                            <div style={{ textAlign: 'right' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataGold.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                            </div>

                        </div>
                    ) : (
                        <Card loading={true}> </Card>
                    )}
                </Card>

                { /* Fill data export excel */}
                <ModelExport dataExport={dataExport} visible={visibleExport} handleCancel={this.handleCancel} loading={loadingExport} />

                <Modal
                    title={'Nhập giá vàng manual'}
                    visible={visibleManual}
                    onCancel={this.handleCancel}

                    footer={null}
                    className="manual-modal">
                    <div>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={24} style={{ textAlign: 'left', marginBottom: 20 }}>
                                <div>Giá mua</div>
                                <Input type="number" value={dataUpdateManual.buy || ""} name='buy' onChange={this.handleChangeInputPriceManual} placeholder="Giá mua" />
                            </Col>
                            <Col span={24} style={{ textAlign: 'left' }}>
                                <div>Giá bán</div>
                                <Input type="number" value={dataUpdateManual.sell || ""} name='sell' onChange={this.handleChangeInputPriceManual} placeholder="Giá bán" />
                            </Col>
                        </Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button type='primary' onClick={this.handleSavePriceManual} size='middle'> Lưu giá thủ công </Button>
                        </Col>
                    </div>
                </Modal>

                <Modal
                    title={'Nhập giá vàng manual'}
                    visible={visibleEditType}
                    onCancel={this.handleCancel}
                    footer={null}
                    className="manual-modal">
                    <div>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={24} style={{ textAlign: 'left', marginBottom: 20 }}>
                                <div>Tên hiển thị</div>
                                <Input type="text" value={newNameType || ""} name='newNameType' onChange={this.handleChangeInput} placeholder="Tên hiển thị" />
                            </Col>
                        </Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button type='primary' onClick={this.handleUpdateType} size='middle'> Cập nhật </Button>
                        </Col>
                    </div>
                </Modal>

            </div>
        );
    }
}



export default Order;