module.exports = {
    siteName: 'Giavang.net Admin',
    copyright: 'Giavang.net©2022',
    logoMiniPath: '/logo.png',
    logoPath: '/logo.png',
    folder_cp: '',
    fixedHeader: true, // sticky primary layout header
    isMobile: false,
    collapse: true,

    ADMIN_URL_PHP: "http://cp.tuyensinh247.com",
    EXAM_ROOM_URL: "http://localhost:3011",
    // API_URL: 'http://localhost:3009',
    API_URL: 'https://api2.giavang.net',
    TINY_API_KEY: 'ASLDKGJHALSGJ',
    //     API_URL: 'http://localhost:7007',

    /* Layout configuration, specify which layout to use for route. */
    layouts: [
        {
            name: 'primary',
            include: [/.*/],
            exclude: [/(\/(en|zh))*\/login/],
        },
    ],

    /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
    i18n: {
        /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
        languages: [
            {
                key: 'pt-br',
                title: 'Português',
                flag: '/portugal.svg',
            },
            {
                key: 'en',
                title: 'English',
                flag: '/america.svg',
            },
            {
                key: 'zh',
                title: '中文',
                flag: '/china.svg',
            },
        ],
        defaultLanguage: 'en',
    },
    ignoreUnicode: (string) => {
        let str = string;
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " "); //eslint-disable-line
        str = str.replace(/ + /g, " ");
        str = str.trim();
        str = str.replace(/ /g, '-');;
        return str;
    }
}
