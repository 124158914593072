import React from 'react';
import './private_layout.css';
import { Layout } from 'antd';
import * as config from '../config/config';
import routes from "../routes";
import { MyLayout } from '../components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { folder_cp } from '../config/config'
import { toggleCollapsed } from "../components/LoadBase/LoadbaseAction"

const { Sider, Header } = MyLayout
// import Error from '../pages/404'


const { Content, Footer } = Layout;


class PrivateLayout extends React.Component {
    state = {
        collapsed: config.collapse,
    };


    componentWillMount() {
        if (_.isEmpty(this.props.user)) {
            this.props.history.push(folder_cp + "/login");
        }
    }

    render() {
        const onCollapseChange = async() => {
            this.props.dispatch(await toggleCollapsed())
        }
        const menus = routes;
        const { user } = this.props;
        const headerProps = {
            menus,
            collapsed: this.props.collapsed,
            avatar: user ? user.avatar : '',
            username: user ? user.username : "",
            fixed: config.fixedHeader,
            onCollapseChange
        };

        const siderProps = {
            menus,
            isMobile: false,
            collapsed: this.props.collapsed,
            location: this.props.location,
            onCollapseChange
        }

        return (
            <Layout>
                <Sider {...siderProps} />
                <div
                    className="PrimaryLayout__container"
                    style={{ paddingTop: config.fixedHeader ? 72 : 0 }}
                    id="primaryLayout"
                >
                    <Header {...headerProps} />
                    {/* <Bread routeList={routes} location={this.props.location}/>  */}
                    <Content className="PrimaryLayout__content">
                        {this.props.children}
                    </Content>
                    <Footer className="antd-pro-global-footer-globalFooter PrimaryLayout__footer" style={{ textAlign: 'center' }}> {config.copyright} </Footer>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.info,
        collapsed: state.base.collapsed
    }
}

export default connect(mapStateToProps)(PrivateLayout)
