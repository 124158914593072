import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middlerware/logger'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// export default () => {
//     let store = createStore(persistedReducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
// }

export default function configureStore(preloadedState) {
    const middlewares = [loggerMiddleware, thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)
    const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)
    //
    // const store = createStore(rootReducer, preloadedState, composedEnhancers)
    //
    // return store
    //
    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['user']
    }
    const persistedReducer = persistReducer(persistConfig, rootReducer)
    let store = createStore(persistedReducer, preloadedState, composedEnhancers)
    let persistor = persistStore(store)
    return {store,persistor}
}
