import { folder_cp } from './config/config'
import PublicLayout from "./layouts/PublicLayout";

import UserLogin from './pages/user/login'

export default [

    /** Tai khoan */
    {
        id: 'user_login',
        path: folder_cp + '/login',
        layout: PublicLayout,
        component: UserLogin,
        isMenu: false
    },

];
