import React, { Component } from 'react';

import Style from './style';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Test extends Component {

    //constructor(props) {
    //    super(props);
    //}
    // In ra status
    renderStatus = (status) => {
        switch (status) {
            case "order-new":
                return "Mới tạo"
            case "order-pending":
                return "Đợi bổ sung quà"
            case "order-confirmed":
                return "Đã duyệt"
            case "order-completed":
                return "Thành công"
            case "order-cancel-before-confirm":
                return "Hủy trước khi duyệt"
            case "order-cancel-after-confirm":
                return "Hủy sau khi duyệt"
            default:
                return status
        }
    }
    render() {
        var data = this.props.data;
        return (
            <div>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="ant-btn change-status-button ant-btn-primary ant-btn-default"
                    table="table-to-xls"
                    sheet="up123"
                    filename="data-order-spoint"
                    buttonText="Tải xuống file Excel" />
                <table id="table-to-xls" style={Style.table}>
                    <tr style={Style.trTop}>
                        <th style={Style.th}>STT</th>
                        <th style={Style.th}>ID</th>
                        <th style={Style.th}>Tài khoản</th>
                        <th style={Style.th}>Người nhận</th>
                        <th style={Style.th}>Địa chỉ đầy đủ</th>
                        <th style={Style.th}>Điện thoại</th>
                        <th style={Style.th}>Email</th>
                        <th style={Style.th}>Thời gian nhận</th>
                        <th style={Style.th}>Số spoint</th>
                        <th style={Style.th}>Trạng thái đơn</th>
                    </tr>

                    {data.length > 0 ? data.map((item, index) => {
                        return (
                            <tr style={Style.trMain}>
                                <td style={Style.td}>{index + 1}</td>
                                <td style={Style.td}>{item.id}</td>
                                <td style={Style.td}>{item.user_order ? item.user_order.id + ' - ' + item.user_order.username : ''}</td>
                                <td style={Style.td}>{item.receiver_name}</td>
                                <td style={Style.td}>{item.address + (" - " + item.district_name) + (" - " + item.city_name)}</td>
                                <td style={Style.td}>{item.receiver_mobile}</td>
                                <td style={Style.td}>{item.receiver_email}</td>
                                <td style={Style.td}>{item.receiver_time}</td>
                                <td style={Style.td}>{item.spoint}</td>
                                <td style={Style.td}>{this.renderStatus(item.status)}</td>
                            </tr>
                        )
                    }) : ('')}


                </table>

            </div>
        );
    }
}

export default Test