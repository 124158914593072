import React from 'react';
import { folder_cp } from './config/config'
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";

// Route Views
import DashBoard from './pages/dashboard/index'
import ListUser from './pages/user/ListUser'
import CreateUser from './pages/user/CreateUser'
import AddGoldTypeUser from './pages/user/AddGoldTypeUser'
import ListRole from './pages/role/ListRole'
import CreateRole from './pages/role/CreateRole'

import ListTypeGold from './pages/gold-type/ListTypeGold'
import ListUrl from './pages/supplier/listUrl'
import SupplierCmd from './pages/supplier/supplierCmd'

import SaveMoney from './pages/save-money/saveMoney'
import SaveMoneyData from './pages/save-money/editData'

import SetupTable from './pages/setup-table/setupTable'
import ListTable from './pages/setup-table/listTable'
import AddTable from './pages/setup-table/addTable'

import Setting from './pages/setting/setting'


import { DashboardOutlined, SettingOutlined, MenuOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons'

export default [
    {
        id: 'dashboard',
        icon: <DashboardOutlined />,
        name: 'Dashboard',
        path: folder_cp + '/',
        exact: true,
        layout: PrivateLayout,
        component: DashBoard,
        isMenu: true
    },

    /** Tai khoan */
    {
        id: 'user',
        icon: <UserOutlined />,
        path: folder_cp + '/user',
        name: 'Quản lý tài khoản',
        permission: 'user_read',
        layout: PrivateLayout,
        component: ListUser,
        isMenu: true,
        exact: false,
    },

    {
        id: 'list_user',
        icon: <UserOutlined />,
        path: folder_cp + '/users',
        name: 'Tài khoản',
        permission: 'user_read',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: ListUser,
        isMenu: true,
        exact: true,
    },
    {
        id: 'create_user',
        icon: <UserOutlined />,
        path: folder_cp + '/create-user',
        name: 'Tạo mới tài khoản',
        permission: 'user_create',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: CreateUser,
        isMenu: false,
        exact: true,
    },
    {
        id: 'edit_user',
        icon: <UserOutlined />,
        path: folder_cp + '/edit-user/:id',
        name: 'Cập nhật tài khoản',
        permission: 'user_update',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: CreateUser,
        isMenu: false,
        exact: true,
    },
    {
        id: 'add_type_user',
        icon: <UserOutlined />,
        path: folder_cp + '/add-type-user/:id',
        name: 'Gán mã vàng cho tài khoản',
        permission: 'user_update',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: AddGoldTypeUser,
        isMenu: false,
        exact: true,
    },
    {
        id: 'role',
        icon: <UserOutlined />,
        path: folder_cp + '/role',
        name: 'Quản lý quyền',
        permission: 'role_read',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: ListRole,
        isMenu: true,
        exact: true,
    },
    {
        id: 'role_create',
        icon: <UserOutlined />,
        path: folder_cp + '/create-role',
        name: 'Tạo mới quyền',
        permission: 'role_create',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: CreateRole,
        isMenu: false,
        exact: true,
    },
    {
        id: 'role_update',
        icon: <UserOutlined />,
        path: folder_cp + '/edit-role/:id',
        name: 'Cập nhật quyền',
        permission: 'role_update',
        menuParentId: 'user',
        breadcrumbParentId: 'user',
        layout: PrivateLayout,
        component: CreateRole,
        isMenu: false,
        exact: true,
    },


    {
        id: 'gold_type_list',
        path: folder_cp + '/gold-type',
        icon: <MenuOutlined />,
        name: 'Danh sách loại vàng',
        permission: 'type_read',
        layout: PrivateLayout,
        // menuParentId: 'gold_type',
        // breadcrumbParentId: 'gold_type',
        component: ListTypeGold,
        isMenu: true,
        exact: true,
    },
    {
        id: 'supplier_url',
        path: folder_cp + '/supplier-url',
        icon: <MenuOutlined />,
        name: 'Nguồn cung vàng',
        permission: 'supplier_read',
        layout: PrivateLayout,
        // menuParentId: 'gold_type',
        // breadcrumbParentId: 'gold_type',
        component: ListUrl,
        isMenu: true,
        exact: true,
    },
    {
        id: 'supplier_cmd',
        path: folder_cp + '/supplier-cmd',
        icon: <MenuOutlined />,
        name: 'Nguồn thu thập dữ liệu',
        permission: 'supplier_read',
        layout: PrivateLayout,
        component: SupplierCmd,
        isMenu: false,
        exact: false,
    },
    {
        id: 'supplier_cmd_child',
        path: folder_cp + '/supplier-cmd/:supplier_code',
        icon: <MenuOutlined />,
        name: 'Nguồn thu thập dữ liệu',
        permission: 'supplier_read',
        layout: PrivateLayout,
        component: SupplierCmd,
        isMenu: false,
        exact: false,
    },

    {
        id: 'save_money',
        path: folder_cp + '/save-money',
        icon: <MenuOutlined />,
        name: 'Lãi suất tiết kiệm',
        permission: 'save_money_read',
        layout: PrivateLayout,
        component: SaveMoney,
        isMenu: true,
        exact: true,
    },

    {
        id: 'save_money_edit',
        path: folder_cp + '/save-money/:id',
        icon: <MenuOutlined />,
        name: 'Sửa dữ liệu',
        permission: 'save_money_update',
        layout: PrivateLayout,
        component: SaveMoneyData,
        isMenu: false,
        exact: false,
    },


    {
        id: 'list_table',
        path: folder_cp + '/list-table',
        icon: <MenuOutlined />,
        name: 'Cài đặt hiển thị bảng vàng',
        permission: 'table_read',
        layout: PrivateLayout,
        // menuParentId: 'gold_type',
        // breadcrumbParentId: 'gold_type',
        component: ListTable,
        isMenu: true,
        exact: true,
    },
    {
        id: 'add_table',
        path: folder_cp + '/add-table',
        icon: <MenuOutlined />,
        name: 'Thêm bảng vàng',
        permission: 'table_create',
        layout: PrivateLayout,
        menuParentId: 'list_table',
        breadcrumbParentId: 'list_table',
        component: AddTable,
        isMenu: false,
        exact: false,
    },
    {
        id: 'edit_table',
        path: folder_cp + '/edit-table/:id',
        icon: <MenuOutlined />,
        name: 'Cập nhật bảng',
        permission: 'table_update',
        layout: PrivateLayout,
        menuParentId: 'list_table',
        breadcrumbParentId: 'list_table',
        component: AddTable,
        isMenu: false,
        exact: false,
    },
    {
        id: 'setup_table',
        path: folder_cp + '/setup-table/:id',
        icon: <MenuOutlined />,
        name: 'Cập nhật bảng',
        permission: 'table_update',
        layout: PrivateLayout,
        menuParentId: 'list_table',
        breadcrumbParentId: 'list_table',
        component: SetupTable,
        isMenu: false,
        exact: false,
    },

    {
        id: 'setting',
        path: folder_cp + '/setting',
        icon: <SettingOutlined />,
        name: 'Cài đặt',
        permission: 'setting_read',
        layout: PrivateLayout,
        component: Setting,
        isMenu: true,
        exact: true,
    },
];
