import { Component } from 'react';
import { connect } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import callApi from "../../utils/callApi";
import { logoutRequest, savePermission } from "../../pages/user/UserAction";
import Cookies from 'js-cookie'
import { folder_cp } from '../../config/config'

class LoadBase extends Component {

    async componentDidMount() {
        this.checkLogin()
    }

    async checkLogin() {
        if (!this.props.user) {
            return;
        }
        const dataFilter = await callApi('v1/user/me', 'get', {});
        if (dataFilter) {
            const data = dataFilter.data || {};
            this.props.dispatch(await savePermission(data.permissions || []));
            return;
        }
        this.props.dispatch(await logoutRequest());
        Cookies.remove('token')
        window.location.href = folder_cp + '/login';
    }

    render() {
        return null
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.info
    }
}

export default connect(mapStateToProps)(LoadBase);