import React from 'react';
import './print.css'


class ComponentPrintAddress extends React.Component {

    render() {
        const { data } = this.props
        // console.log(data);
        return (
            <div>
                {data ? (
                    <div >
                        {Array.isArray(data) ? (
                            <div className="main-address">
                                {data.map((item, index) => {
                                    return (
                                        <div className="item-address" key={index}>
                                            <div className="item-title">#{index + 1} - Thông tin địa chỉ </div>
                                            <div className="child"><b>Người nhận:</b> {item.receiver_name} </div>
                                            <div className="child"><b>Số điện thoại:</b> {item.receiver_mobile} </div>
                                            <div className="child"><b>Địa chỉ:</b> {item.address} - {item.district_name} - {item.city_name} </div>
                                            <div className="child"><b>Thời gian nhận:</b> {item.receiver_time} </div>
                                        </div>)
                                }

                                )}

                            </div>

                        ) : (
                                <div className="main-address">

                                    <div className="item-address">
                                        <div className="item-title">#1 - Thông tin địa chỉ</div>
                                        <div className="child"><b>Người nhận:</b> {data.receiver_name} </div>
                                        <div className="child"><b>Số điện thoại:</b> {data.receiver_mobile} </div>
                                        <div className="child"><b>Địa chỉ:</b> {data.address} - {data.district_name} - {data.city_name} </div>
                                        <div className="child"><b>Thời gian nhận:</b> {data.receiver_time} </div>
                                    </div>
                                </div>

                            )}




                    </div>
                ) : (<div></div>)
                }
            </div>

        );
    }
}
export default ComponentPrintAddress