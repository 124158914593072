import React from 'react';
import { Link } from 'react-router-dom';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import {
    Card,
    Table,
    Row,
    Col,
    Input,
    Popconfirm,
    Pagination,
    Button,
    message,
    Select,
    Modal,
    Switch,
    DatePicker,
} from 'antd';
import callApi from "../../utils/callApi";
import moment from 'moment'
import { folder_cp } from '../../config/config';

const { RangePicker } = DatePicker;
const { Option } = Select;
class SaveMoney extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataTable: null,
            visibleModal: false,
            page: 1,
            row_per_page: 20,
            keyword_in: "",
            keyword: "",
            supplier_code: this.props.match.params.supplier_code || null,
            isModalOpen: false,
            is_delete: [],

            save_data: {
                code: null,
                name: null
            },
            update_data: {
                code: null,
                name: null
            }

        };
    }

    // Tai du lieu tu api
    callApiGetSaveMoney = async (data = {}) => {
        await this.setState({
            loading: true
        })
        const dataFilter = await callApi('v1/save-money', 'get', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            return this.setState({ dataTable: dataFilter.data, loading: false })
        }
    }

    callApiCreateSaveMoney = async (data = {}) => {
        const dataFilter = await callApi('v1/save-money', 'POST', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            message.success("Thêm mới thành công")
            this.handleCancel();
            this.refreshData()
        }
    }

    callApiUpdateSaveMoney = async (code, data = {}) => {
        const dataFilter = await callApi(`v1/save-money/${code}`, 'PUT', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            message.success("Thành công")
            this.handleCancel();
            this.refreshData()
        }
    }
    refreshData = async () => {
        var dataQuery = {
            supplier_code: this.state.supplier_code,
            is_delete: this.state.is_delete
        }

        await this.callApiGetSaveMoney(dataQuery)
    }

    handleCancel = () => {
        this.setState({
            isModalOpen: false,
            visibleModal: false
        })
    }

    showModalUpdate = (record) => {
        const { code, name } = record;
        const { update_data } = this.state;
        update_data.code = code;
        update_data.name = name;

        this.setState({
            visibleModal: true,
            update_data: update_data
        })
    }

    async componentDidMount() {
        // Query New
        await this.callApiGetSaveMoney({
            supplier_code: this.state.supplier_code
        })

    }

    handleChangeInput = async (event, field = null) => {
        const { name, value } = event.target;
        if (field) {
            const data = this.state[field] || {};
            data[name] = value;
            await this.setState({
                [field]: data
            });
            return;
        }
        await this.setState({
            [name]: value
        });
    }

    selectAfter = (defaultValue) => {
        return (
            <Select value={defaultValue} name="keyword_in" onChange={this.handleChangeSelect('keyword_in')} className="select-after">
                <Option value="" hidden>Tìm theo</Option>
                <Option value="name">Tên</Option>
                <Option value="code">Code</Option>
            </Select>
        )
    }


    handleChangeSelect = (name, field = null) => {
        if (field) {
            const data = this.state[field] || {};
            return async function (value) {
                data[name] = value;
                await this.setState({
                    [field]: value
                });
            }.bind(this);
        }
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    handleResetFilter = async () => {
        await this.setState({
            page: 1,
            keyword_in: "",
            keyword: "",
            is_delete: []

        })
        this.handleFilter()
    }

    // Filter
    handleFilter = async () => {
        await this.setState({ page: 1 })
        var dataQuery = {
            page: 1,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            is_delete: this.state.is_delete,
            supplier_code: this.state.supplier_code
        }
        this.callApiGetSaveMoney(dataQuery)
    }

    onChangePaginatePage = async (current, pageSize) => {
        await this.setState({
            page: current,
            row_per_page: pageSize
        })
        var dataQuery = {
            page: this.state.page,
            row_per_page: this.state.row_per_page,
            keyword_in: this.state.keyword_in,
            keyword: this.state.keyword,
            supplier_code: this.state.supplier_code
        }
        this.callApiGetSaveMoney(dataQuery)
    }

    showModalAdd() {
        this.setState({ isModalOpen: true })
    };

    handleAddSaveMoney() {
        const { save_data } = this.state;
        const { code, name } = save_data || {};
        if (!code || !name) {
            message.error("Bạn vui lòng nhập đủ thông tin", 3);
            return;
        }

        this.callApiCreateSaveMoney(save_data)
    }

    handleUpdateSaveMoney() {
        const { update_data } = this.state;
        const { code, name } = update_data || {};
        if (!code || !name) {
            message.error("Bạn vui lòng nhập đủ thông tin", 3);
            return;
        }
        delete update_data.code;
        this.callApiUpdateSaveMoney(code, update_data)
    }




    render() {
        const { isModalOpen, save_data, update_data, loading, dataTable, error_msg, type, visibleModal, keyword, is_delete, row_per_page, page } = this.state
        const columnsTable = [
            {
                title: 'ID',
                width: '5%',
                key: "ID",
                render: (text, record, index) => {
                    return (
                        <div> {record.id} </div>
                    )
                }
            },
            {
                title: 'Code',
                width: '15%',
                key: "code",
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div>
                            {record.code}
                        </div>
                    )
                }
            },
            {
                title: 'Tên',
                width: '50%',
                key: "name",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div className='title'> {record.name} </div>
                        </div>
                    )
                }
            },

            {
                title: 'Thao tác',
                align: "center",
                width: '30%',
                key: "status",
                render: (text, record, index) => {
                    return (
                        <div>
                            <Button type={"primary"} size="small" onClick={() => this.showModalUpdate(record)}> Update </Button>
                            <div style={{ display: "inline-block", width: 10 }}> </div>
                            <Link to={`${folder_cp}/save-money/${record.id}`}>
                                <Button type={"primary"} size="small" onClick={() => this.showModalUpdate(record)}> Dữ liệu </Button>
                            </Link>
                        </div>
                    )
                }
            }
        ];

        return (
            <div className="order-list">
                <Card loading={loading}>
                    <Row gutter={24} className="filter">
                        <Col span={18}>
                            <Row gutter={24} className="filter">
                                <Col span={12}>
                                    <div className="filter-item">
                                        <div className="filter-lable">
                                            Từ khóa:
                                        </div>
                                        <div className="filter-input">
                                            <Input value={keyword} onChange={this.handleChangeInput} name="keyword" addonAfter={this.selectAfter(this.state.keyword_in)} />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div className="filter-item">
                                        <div className="filter-lable">
                                            Trạng thái:
                                        </div>
                                        <div className="filter-input">
                                            <Select value={is_delete} name="is_delete" placeholder="Chọn trạng thái" className="select-status" onChange={this.handleChangeSelect('is_delete')} mode="multiple" >
                                                <Option key={0} value={0}>Đang hoạt động</Option>
                                                <Option key={1} value={1}>Đã xoá</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col span={6}>
                            <div className="filter-item">
                                <Button className="filter-button" onClick={this.handleResetFilter} icon={<RedoOutlined />} > Reset Filter</Button>
                                <Button type="primary" onClick={this.handleFilter} className="filter-button" icon={<FilterOutlined />} style={{ marginLeft: 10 }}>
                                    Tìm kiếm
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Card loading={loading}>
                    {dataTable && (
                        <>
                            <Row span={24} className="head-table" style={{ marginBottom: 10 }}>
                                <Col span={12}>
                                    <span> Tổng số <b>{dataTable.total}</b> bản ghi </span>
                                    <Button style={{ marginLeft: 10 }} type={"primary"} size="small" onClick={() => this.showModalAdd()}> Thêm mới </Button>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                                    </div>
                                </Col>
                            </Row>

                            <Table loading={loading} rowKey="id" columns={columnsTable} dataSource={dataTable.docs} size="small" pagination={false} />
                            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
                                <Pagination pageSize={row_per_page} size="small" current={page} onChange={this.onChangePaginatePage} total={dataTable.total} onShowSizeChange={this.onChangePaginatePage} showSizeChanger showQuickJumper />
                            </div>
                            <Modal
                                title={'Cập nhật lãi suất tiết kiệm'}
                                visible={visibleModal}
                                onCancel={this.handleCancel}
                                footer={null}
                                className="manual-modal">
                                <div className='wrapInput'>
                                    <div className='inputLabel'> Tên lãi suất tiết kiêm </div>
                                    <Input placeholder="" value={update_data.name} name="name" onChange={(e) => { this.handleChangeInput(e, "update_data") }} />
                                </div>

                                <div className='wrapInput' style={{ marginTop: 15 }}>
                                    <div className='inputLabel' > Mã Code </div>
                                    <Input placeholder="" value={update_data.code} disabled={true} />
                                </div>

                                <div className='wrapButton' style={{ textAlign: "center", marginTop: 25 }}>
                                    <Button type="primary" onClick={() => this.handleUpdateSaveMoney()}> Cập nhật </Button>
                                </div>
                            </Modal>

                        </>
                    )}

                </Card>
                <Modal title="Thêm mới" footer={null} visible={isModalOpen} onCancel={this.handleCancel}>
                    <div className='wrapInput'>
                        <div className='inputLabel'> Tên lãi suất tiết kiêm </div>
                        <Input placeholder="" value={save_data.name} name="name" onChange={(e) => { this.handleChangeInput(e, "save_data") }} />
                    </div>

                    <div className='wrapInput' style={{ marginTop: 15 }}>
                        <div className='inputLabel' value={save_data.code} > Mã Code </div>
                        <Input placeholder="" name="code" onChange={(e) => { this.handleChangeInput(e, "save_data") }} />
                    </div>

                    <div className='wrapButton' style={{ textAlign: "center", marginTop: 25 }}>
                        <Button type="primary" onClick={() => this.handleAddSaveMoney()}> Thêm mới </Button>
                    </div>

                </Modal>

            </div>
        );
    }
}


export default SaveMoney;