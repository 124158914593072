import React from 'react'
import {
    Row,
    Col,
    Modal,
} from 'antd';
// Export
import DataExcel from "../export/export-excel";

function ModelStatusAfter(props) {
    return (
        <Modal
            title={'Xuất dữ liệu đơn hàng'}
            visible={props.visible}
            onCancel={props.handleCancel}
            footer={null}
            className="order-modal">
            <Row style={{ marginBottom: 20 }}>
                <Col span={24} style={{textAlign:'center'}}>
                    <DataExcel data={props.dataExport}/>
                </Col>
            </Row>
            
        </Modal>
    )
}
export default ModelStatusAfter