import React from 'react';
import { FormOutlined, PlusOutlined, RollbackOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    Card,
    Input,
    Button,
    Select,
    Modal,
    message,
    Form,
    Upload,
    Row, Col, Table
} from 'antd';

import callApi from "../../utils/callApi";
import './setting.css'


const { Option } = Select;
const { TextArea } = Input;

const layoutForm = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    wrapperCol: { offset: 7, span: 10 },
};



function validateRequire(value) {
    if (value && value !== "") {
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }
    return {
        validateStatus: 'error',
        errorMsg: 'Trường này là bắt buộc!',
    };
}

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requireForm: {},
            loadingTable: true,
            dataTable: {
                EMAIL_NOTIFY_ERROR: "",
                APP_PRICE_USD_VND: null,
                APP_TRANSPORT_FEE: null,
                APP_INSURANCE_FEE: null,
                APP_TAX_FEE: null,
                APP_CUSTOMS_FEE: null,
                APP_MACHINING_FEE: null
            },
            listField: [],
            listTypes: null,
        };
    }



    getDataInApi = async (data = {}) => {
        await this.setState({
            loadingTable: true
        })
        const dataTable = await callApi(`v1/setting`, 'get', data);
        if (dataTable && dataTable.status) {
            var product = dataTable.data;
            this.setState({ dataTable: product })
        }
    }


    async componentDidMount() {
        this.getDataInApi()
    }

    handleChangeSelect = (name) => {
        const { dataTable } = this.state;
        return async function (value) {
            dataTable[name] = value;
            await this.setState({ dataTable: dataTable });
        }.bind(this);
    }


    updateSetting = async () => {
        var dataUpdate = this.validateField()
        if (!dataUpdate) {
            return false;
        }
        const dataTable = await callApi(`v1/setting`, 'PUT', { field:  dataUpdate});
        if (dataTable && dataTable.status) {
            message.success(dataTable.msg);
            this.getDataInApi()
            return
        }
        message.error(dataTable.msg);

    }


    validateField = () => {
        var { EMAIL_NOTIFY_ERROR } = this.state.dataTable
        var dataResult = this.state.dataTable;
        if (!EMAIL_NOTIFY_ERROR) {
            message.error("Bạn phải điền đầy đủ các trường bắt buộc")
            return false
        }
        return dataResult;
    }


    handleRequire = async event => {
        const { name, value } = event.target;
        const { dataTable } = this.state;
        dataTable[name] = value;
        await this.setState({
            dataTable: dataTable,
            requireForm: {
                ...this.state.requireForm,
                [name]: validateRequire(value)
            }
        });
    };

    // Ham duoc goi khi filter
    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }


    render() {
        const { dataTable, requireForm, listTypes } = this.state;

        return (
            <Card className="edit-order" >
                <div className="form">
                    <Form {...layoutForm} name="basic">

                        <Form.Item label="Email thông báo lỗi:"
                            validateStatus={requireForm.EMAIL_NOTIFY_ERROR ? requireForm.EMAIL_NOTIFY_ERROR.validateStatus : null}
                            help={requireForm.EMAIL_NOTIFY_ERROR ? requireForm.EMAIL_NOTIFY_ERROR.errorMsg : null}>
                            <Input value={dataTable.EMAIL_NOTIFY_ERROR || ""} name="EMAIL_NOTIFY_ERROR" onChange={this.handleRequire} />
                        </Form.Item>


                        <Form.Item label="Tỷ giá USDVND tự do:"
                            validateStatus={requireForm.APP_PRICE_USD_VND ? requireForm.APP_PRICE_USD_VND.validateStatus : null}
                            help={requireForm.APP_PRICE_USD_VND ? requireForm.APP_PRICE_USD_VND.errorMsg : null}>
                            <Input value={dataTable.APP_PRICE_USD_VND || ""} name="APP_PRICE_USD_VND" onChange={this.handleRequire} suffix="đ"/>
                        </Form.Item>


                        <Form.Item label="Phí vận chuyển (VD: 0.75$):"
                            validateStatus={requireForm.APP_TRANSPORT_FEE ? requireForm.APP_TRANSPORT_FEE.validateStatus : null}
                            help={requireForm.APP_TRANSPORT_FEE ? requireForm.APP_TRANSPORT_FEE.errorMsg : null}>
                            <Input value={dataTable.APP_TRANSPORT_FEE || ""} name="APP_TRANSPORT_FEE" onChange={this.handleRequire} suffix="$"/>
                        </Form.Item>


                        <Form.Item label="Phí bảo hiểm là (VD: 0.5$):"
                            validateStatus={requireForm.APP_INSURANCE_FEE ? requireForm.APP_INSURANCE_FEE.validateStatus : null}
                            help={requireForm.APP_INSURANCE_FEE ? requireForm.APP_INSURANCE_FEE.errorMsg : null}>
                            <Input value={dataTable.APP_INSURANCE_FEE || ""} name="APP_INSURANCE_FEE" onChange={this.handleRequire} suffix="$"/>
                        </Form.Item>


                        <Form.Item label="Thuế nhập khẩu (VD: 1.0%):"
                            validateStatus={requireForm.APP_TAX_FEE ? requireForm.APP_TAX_FEE.validateStatus : null}
                            help={requireForm.APP_TAX_FEE ? requireForm.APP_TAX_FEE.errorMsg : null}>
                            <Input value={dataTable.APP_TAX_FEE || ""} name="APP_TAX_FEE" onChange={this.handleRequire} suffix="%"/>
                        </Form.Item>

                        <Form.Item label="Phí Hải quan (VD: 10.000đ):"
                            validateStatus={requireForm.APP_CUSTOMS_FEE ? requireForm.APP_CUSTOMS_FEE.validateStatus : null}
                            help={requireForm.APP_CUSTOMS_FEE ? requireForm.APP_CUSTOMS_FEE.errorMsg : null}>
                            <Input value={dataTable.APP_CUSTOMS_FEE || ""} name="APP_CUSTOMS_FEE" onChange={this.handleRequire} suffix="đ"/>
                        </Form.Item>


                        <Form.Item label="Phí gia công (VD: 40.000đ):"
                            validateStatus={requireForm.APP_MACHINING_FEE ? requireForm.APP_MACHINING_FEE.validateStatus : null}
                            help={requireForm.APP_MACHINING_FEE ? requireForm.APP_MACHINING_FEE.errorMsg : null}>
                            <Input value={dataTable.APP_MACHINING_FEE || ""} name="APP_MACHINING_FEE" onChange={this.handleRequire}  suffix="đ"/>
                        </Form.Item>



                        <Form.Item {...tailLayout}>
                            <div className="disInlineBlock">
                                <Button type="primary" onClick={(e) => this.updateSetting()} icon={<FormOutlined />}> Cập nhật </Button>
                            </div>
                            <span style={{ width: 50, display: "inline-block" }}></span>
                            <Button type="danger" ghost onClick={() => { this.props.history.goBack() }} icon={<RollbackOutlined />}> Quay lại </Button>

                        </Form.Item>
                    </Form>
                </div>
            </Card>
        );
    }
}



export default Setting;