import * as ActionType from './LoadbaseAction'
import _ from 'lodash'

export const initialState = {
    socket: null,
    collapsed: false,
    menu_key: [],
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ActionType.CONNECT_SOCKET:
            return _.assign({}, state, { socket: action.data })

        case ActionType.TOGGLE_COLLAPSED:
            return _.assign({}, state, { collapsed: !state.collapsed })

        case ActionType.CURRENT_KEY_MENU:
            return _.assign({}, state, { menu_key: action.key })

        default:
            return state
    }
}
