import * as ActionType from './UserAction'
import _ from 'lodash'

export const initialState = {
    permissions: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionType.LOGIN_SUCCESS:
            return _.assign({}, state, { info: action.data })
        case ActionType.LOGIN_OUT:
            return _.assign({}, state, { info: null })
        case ActionType.CHANGE_COLLAPSE:
            return _.assign({}, state, { collapse: action.collapse })
        case ActionType.SAVE_PERMISSION:
            return _.assign({}, state, { permissions: action.data })

        default:
            return state
    }
}
