import React, { useState, useEffect } from 'react'
import { Input, Button, Form, Modal, Checkbox, Card, Row, Col, h2, message, Switch } from 'antd'
import callApi from '../../utils/callApi'

import { Link } from 'react-router-dom';
import { PlusOutlined, UpSquareOutlined, RollbackOutlined } from '@ant-design/icons';
import { folder_cp } from '../../config/config';

const { TextArea } = Input;
const defaultPrice = {
    time_7d: null,
    time_14d: null,
    time_1m: null,
    time_2m: null,
    time_3m: null,
    time_6m: null,
    time_9m: null,
    time_12m: null,
    time_24m: null,
    time_36m: null,
    time_48m: null,
    time_60m: null,
    not_expire: null,
    status: "deactive"
}

function EditData(props) {
    const [dataResource, setDataResource] = useState([]);
    const [saveMoneyId, setSaveMoneyId] = useState(null);
    const [saveMoney, setSaveMoney] = useState({});
    const [saveMoneyOnline, setSaveMoneyOnline] = useState({ ...defaultPrice });
    const [saveMoneyOffline, setSaveMoneyOffline] = useState({ ...defaultPrice });

    useEffect(() => {
        setSaveMoneyId(props.match.params.id);
        callApiGetSaveMoney(props.match.params.id)
    }, []);


    const callApiGetSaveMoney = async (id, data = {}) => {
        const dataFilter = await callApi(`v1/save-money/${id}`, 'get', data);
        if (dataFilter && dataFilter.status) {

            setSaveMoney(dataFilter.data);
            const listData = dataFilter.data.list_data || [];
            const online = listData.find(item => item.type == "online");
            if (online) {
                setSaveMoneyOnline(online)
            }
            const offline = listData.find(item => item.type == "offline");
            if (offline) {
                setSaveMoneyOffline(offline)
            }
        }
    }


    const callApiUpdateSaveMoneyData = async (code, data = {}) => {
        const dataFilter = await callApi(`v1/save-money/data/${code}`, 'PUT', data);
        if (dataFilter && dataFilter.status) {
            message.success("Thành công", 3);
            props.history.push(folder_cp + "/save-money");
        }
    }
    const handleChangeInputOnline = async (event) => {
        const { name, value } = event.target;
        saveMoneyOnline[name] = value;
        setSaveMoneyOnline({ ...saveMoneyOnline })
    }

    const handleChangeInputOffline = async (event) => {
        const { name, value } = event.target;
        saveMoneyOffline[name] = value;
        setSaveMoneyOffline({ ...saveMoneyOffline })
    }


    const getLabelField = (fieldName) => {
        const labelName = {
            time_7d: "7 Ngày",
            time_14d: "14 Ngày",
            time_1m: "1 Tháng",
            time_2m: "2 Tháng",
            time_3m: "3 Tháng",
            time_6m: "6 Tháng",
            time_9m: "9 Tháng",
            time_12m: "12 Tháng",
            time_24m: "24 Tháng",
            time_36m: "36 Tháng",
            time_48m: "48 Tháng",
            time_60m: "60 Tháng",
            not_expire: "Không giới hạn"
        }
        return labelName[fieldName] || fieldName;
    }

    const getLabelType = (type) => {
        const labelName = {
            online: "Tiết kiệm trực tuyến",
            offline: "Tại quầy"
        }
        return labelName[type] || type;
    }

    const handleUpdateDataSaveMoney = () => {
        const dataUpdate = {
            data_online: saveMoneyOnline,
            data_offline: saveMoneyOffline
        }
        callApiUpdateSaveMoneyData(saveMoney.code, dataUpdate)
    }

    const handleSetType = (typeData, type) => {
        let statusT = "deactive";
        if (type) {
            statusT = "active"
        }
        console.log(typeData);
        if (typeData == 1) {
            saveMoneyOffline['status'] = statusT;
            setSaveMoneyOffline({ ...saveMoneyOffline })
        }

        if (typeData == 2) {
            saveMoneyOnline['status'] = statusT;
            setSaveMoneyOnline({ ...saveMoneyOnline })
        }
    }

    const listFieldUpdate = ["not_expire", "time_7d", "time_14d", "time_1m", "time_2m", "time_3m", "time_6m", "time_9m", "time_12m", "time_24m", "time_36m", "time_48m", "time_60m"]

    return (
        <div className='create-user'>
            <Card>

                <Link to={folder_cp + `/save-money`}> <span> Quay lại <RollbackOutlined /></span></Link>
                <div style={{ fontSize: 24, marginBottom: 20 }}> {saveMoney.name || ""}</div>

                <div style={{ background: "beige", marginBottom: 30, padding: 15, marginTop: 20 }}>
                    <div className='labelData' style={{ fontSize: 20 }}>
                        Gửi tiết kiệm tại quầy
                        <div style={{ display: "inline-block", marginLeft: 20 }}>
                            <Switch checked={saveMoneyOffline.status == "active" ? true : false} onChange={handleSetType.bind(this, 1)} />
                        </div>
                    </div>
                    <Row gutter={24}>
                        {listFieldUpdate.map((field, index) => (
                            <Col key={`off_${index}`} span={6} style={{ marginBottom: 10, marginTop: 10 }}>
                                <div>
                                    <div className='labelInput'>  {getLabelField(field)}</div>
                                    <Input type='number' style={{ width: "100%" }} name={field} onChange={(e) => handleChangeInputOffline(e)} placeholder="..." value={saveMoneyOffline[field]} addonAfter="%" />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                <div style={{ background: "beige", marginBottom: 30, padding: 15 }}>
                    <div className='labelData' style={{ fontSize: 20 }}>
                        Gửi tiết kiệm online
                        <div style={{ display: "inline-block", marginLeft: 20 }}>
                            <Switch checked={saveMoneyOnline.status == "active" ? true : false} onChange={handleSetType.bind(this, 2)} />
                        </div>
                    </div>
                    <Row gutter={24}>
                        {listFieldUpdate.map((field, index) => (
                            <Col key={`on_${index}`} span={6} style={{ marginBottom: 10, marginTop: 10 }}>
                                <div>
                                    <div className='labelInput'>  {getLabelField(field)}</div>
                                    <Input type='number' style={{ width: "100%" }} name={field} onChange={(e) => handleChangeInputOnline(e)} placeholder="..." value={saveMoneyOnline[field]} addonAfter="%" />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                <div style={{ textAlign: "center" }}>
                    <Button type={"primary"} onClick={() => handleUpdateDataSaveMoney()}> Cập nhật </Button>
                </div>

            </Card>
        </div>
    )
}

export default EditData;