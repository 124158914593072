import React, { useState, useEffect } from 'react'
import { Input, Button, Form, Modal, Checkbox, Card, Row, Col, message } from 'antd'
import callApi from '../../utils/callApi'
const { TextArea } = Input;

function CreateRole(props) {
    const [dataResource, setDataResource] = useState([]);
    const [roleId, setRoleId] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [role, setRole] = useState({ name: null, description: null, permissions: [] });

    useEffect(() => {
        callApiGetPermission()
        if (props.match.params.id) {
            setRoleId(props.match.params.id)
            callApiGetUser(props.match.params.id)
        }
    }, []);

    const onFinish = async (values) => {
        if (!roleId) {
            return callApiCreateRole(role);
        }
        return callApiUpdateRole(roleId, role);
    };

    const callApiGetUser = async (id) => {
        const response = await callApi(`v1/role/${id}`, 'GET', {});
        if (response && response.status) {
            setRole({ ...response.data })
            return
        }
        message.error(response.msg);
    }

    const callApiGetPermission = async (id) => {
        const response = await callApi(`v1/permission`, 'GET', {});
        if (response && response.status) {
            const dataPermission = [];
            const selected = [];
            response.data.map(item => {
                dataPermission.push({
                    label: item.description, value: item.name || "",
                })
                if (item.is_selected) {
                    selected.push(item.name)
                }
            })
            role.permissions = [...selected];
            setPermissions([...dataPermission]);
            setRole({ ...role })
            return
        }
        message.error(response.msg);
    }


    const callApiUpdateRole = async (id, dataUpdate) => {
        const response = await callApi(`v1/role/${id}`, 'PUT', dataUpdate);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    const callApiCreateRole = async (values) => {
        const response = await callApi(`v1/role`, 'POST', values);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeInput = async (event) => {
        const { name, value } = event.target;
        setRole({ ...role, [name]: value })
    }
    const onChangeCheckbox = (checkedValues) => {
        const newRole = { ...role };
        newRole.permissions = [...checkedValues];
        setRole({ ...newRole })
    }

    return (
        <div className='create-role'>
            <Card>
                <Row gutter={24}>
                    <Col span={18} offset={3}>
                        <Form
                            name="basic"
                            // labelCol={{ span: 8 }}
                            // wrapperCol={{ span: 16 }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Tên role"
                                rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                            >
                                <Input value={role.name} name='name' onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Mô tả"
                                rules={[{ required: false }]}
                            >
                                <TextArea rows={4} value={role.description} onChange={handleChangeInput} name='description' />
                            </Form.Item>

                            <Form.Item
                                label="Chọn quyền"
                                rules={[{ required: false }]}
                            >
                                <div className='listRoles'>
                                    <Checkbox.Group className='group-type-user' options={permissions} value={role.permissions} onChange={onChangeCheckbox} />
                                </div>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit"> {roleId? "Cập nhật nhóm quyền" : "Tạo tài nhóm quyền"} </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default CreateRole;