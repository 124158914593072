import React, { useState, useEffect } from 'react'
import { Input, Button, Form, Modal, Checkbox, Card, Row, Col, message } from 'antd'
import callApi from '../../utils/callApi'
const { TextArea } = Input;

function CreateUser(props) {
    const [dataResource, setDataResource] = useState([]);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState({ username: null, fullname: null, password: null, description: null });

    useEffect(() => {
        if (props.match.params.id) {
            setUserId(props.match.params.id)
            callApiGetUser(props.match.params.id)
        }
    }, []);

    const onFinish = async (values) => {
        if (!userId) {
            return callApiCreateAccount(user);
        }
        return callApiUpdateUser(userId, user);
    };

    const callApiGetUser = async (id) => {
        const response = await callApi(`v1/user/${id}`, 'GET', {});
        if (response && response.status) {
            setUser({ ...response.data })
            return
        }
        message.error(response.msg);
    }


    const callApiUpdateUser = async (id, dataUpdate) => {
        const response = await callApi(`v1/user/${id}`, 'PUT', dataUpdate);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    const callApiCreateAccount = async (values) => {
        const response = await callApi(`v1/user/create-account`, 'POST', values);
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeInput = async (event) => {
        const { name, value } = event.target;
        setUser({...user, [name]: value})
    }
    return (
        <div className='create-user'>
            <Card>
                <Row gutter={24}>
                    <Col span={12} offset={3}>
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Họ tên"
                                // name="fullname"
                                rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                            >
                                <Input value={user.fullname} name='fullname' onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Username"
                                // name="username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input value={user.username} name='username' onChange={handleChangeInput}  disabled={Boolean(props.match.params.id)} />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                // name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder='******'  name='password' onChange={handleChangeInput} />
                            </Form.Item>

                            <Form.Item
                                label="Mô tả"
                                // name="description"
                                rules={[{ required: false, message: 'Please input your password!' }]}
                            >
                                <TextArea rows={4} value={user.description} onChange={handleChangeInput}  name='description' />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit"> Tạo tài khoản </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default CreateUser;