import React, { Component } from 'react';
import { PlusOutlined, UpSquareOutlined, RollbackOutlined } from '@ant-design/icons';
import { Row, Col, Card, Table, Input, Typography, Select, Tag, Button, message, Popconfirm } from 'antd';
import { TweenOneGroup } from 'rc-tween-one'
import { Link } from 'react-router-dom';
import { folder_cp } from '../../config/config'
import callApi from "../../utils/callApi";


import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import './table.css'

const { Option } = Select;
const { TextArea } = Input;


let dragingIndex = -1;
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);


class AddProductType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputVisible: false,
            inputValue: '',
            nameType: '',  // tên biến thể
            slugType: '',    // mã biến thể,
            dataSource: [],
            item_type: 'html',
            item_value: null,
            item_title: null,
            typeGolds: null

        }
    }

    callApiGetTableItem = async () => {
        const table_id = this.props.match.params.id;
        const listProductType = await callApi(`v1/table/${table_id}/item`, 'get', {});
        if (listProductType && listProductType.status) {
            this.setState({ dataSource: listProductType.data })
        }
    }

    callApiGetType = async (data = { row_per_page: 1000 }) => {
        const dataFilter = await callApi('v1/type', 'get', data);
        if (dataFilter && dataFilter.status) {
            return this.setState({ typeGolds: dataFilter.data.docs })
        }
    }

    componentDidMount = async () => {
        this.callApiGetTableItem();
        this.callApiGetType();
    }

    handleDelProductType = async (record) => {
        const listProductType = await callApi(`v1/table/item/${record}`, 'delete', {});
        if (listProductType && listProductType.status) {
            message.success('Đã xóa, thưa sếp! (¬_¬)');
            this.callApiGetTableItem();
        } else {
            message.error('Lỗi em!');
        }
    }

    handleSaveItem = async () => {
        const { item_type, item_value, item_title } = this.state;
        const table_id = this.props.match.params.id;
        if (!item_type || !item_value) {
            message.error('Bạn vui lòng điền đầy đủ thông tin');
            return;
        }

        const saveProducType = await callApi(`v1/table/${table_id}/item`, 'post', { type: item_type, value: item_value, title: item_title });
        if (saveProducType && saveProducType.status) {
            message.success('Em thêm cho sếp rồi ạ! (｀ω´ )');
            this.callApiGetTableItem();
            return
        }
        message.error('Lỗi em! ');

    }
    handleNameslug = (e) => {
        this.setState({ slugType: e.target.value })
    }
    handleNameType = (e) => {
        this.setState({ nameType: e.target.value })
    }


    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };


    handleSaveSortVariant = async () => {
        const { dataSource } = this.state;
        let list_id = dataSource.map(x => x['id']);
        const table_id = this.props.match.params.id;

        const listProductType = await callApi(`v1/table/${table_id}/item/short`, 'put', { list_id: list_id });
        if (listProductType && listProductType.status) {
            message.success('Đã lưu sắp xếp, thưa sếp! (¬_¬)');
            this.callApiGetTableItem();
            return;
        }
        message.error('Chị làm lỗi em rồi!');

    }

    // Drag 
    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { dataSource } = this.state;
        const dragRow = dataSource[dragIndex];

        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            }),
        );
    };

    handleChangeInput = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    handleChangeSelect = (name) => {
        return async function (value) {
            await this.setState({
                [name]: value
            });
        }.bind(this);
    }

    renderTypes = (type) => {
        switch (type) {
            case "html":
                return "Html"
            case "code":
                return "Code"
            default:
                return type
        }
    }

    render() {
        const { Text, Title } = Typography;
        const { inputValue, item_type, item_value, item_title, nameType, slugType, dataSource, typeGolds } = this.state;

        const columns = [
            {
                title: 'Loại',
                width: '15%',
                render: (record, index) => (
                    <span>{this.renderTypes(record.type)}</span>
                )
            },
            {
                title: 'Dữ liệu',
                width: '35%',
                render: (record, index) => {
                    return (
                        <div>{record.value || ""}</div>
                    )
                }
            },
            {
                title: 'Thứ tự',
                align: "center",
                width: '10%',
                render: (record, index) => (
                    <span>{record.order || 0}</span>
                )
            },
            {
                title: 'Thao tác',
                dataIndex: 'id',
                width: '10%',
                render: (record) => (
                    <Popconfirm
                        title="Sếp muốn xóa thiệc ư? (~_~)"
                        onConfirm={() => this.handleDelProductType(record)}
                        okText="Ừ, nói nhiều"
                        cancelText="Không, tý nữa!"
                    >
                        <a href="#!">Delete</a>
                    </Popconfirm>
                )
            },
        ];
        return (
            <Card>
                <Title level={3}><Link to={folder_cp + `/list-table`}> <span> Quay lại <RollbackOutlined /></span></Link>
                </Title>
                <Row gutter={12}>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <div>Chọn loại</div>
                            <Select value={item_type} name="item_type" onChange={this.handleChangeSelect('item_type').bind(this)} style={{ width: 300, marginBottom: 12 }} >
                                <Option value="html" >Html</Option>
                                <Option value="code" >Code Price - [XAU]</Option>
                            </Select>

                            <div>Dữ liệu</div>
                            {item_type == "html" ? (
                                <TextArea rows={4} value={item_value} name="item_value" onChange={this.handleChangeInput} />
                            ) : (
                                <>
                                    <Input value={item_title} name="item_title" onChange={this.handleChangeInput}  placeholder="Tên hiển thị" />
                                    <div style={{"height": "10px"}}></div>
                                    <Select showSearch value={item_value} name="item_value" placeholder="Chọn mã vàng" className="selectTableField" onChange={this.handleChangeSelect('item_value')} filterOption={(input, option) => { return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 }}  >
                                        {typeGolds && typeGolds.map((item, index) => <Option key={index} value={item.code} title={item.title}> {`[${item.code}] ${item.title}`}</Option>)}
                                    </Select>
                                </>
                            )}

                            <div>
                                <div style={{ textAlign: 'right' }}>
                                    <Button type="primary" size="small" style={{ marginTop: 24 }} onClick={() => this.handleSaveItem()}>
                                        <PlusOutlined /> Thêm vào
                                    </Button>
                                </div>
                            </div>

                        </Card>
                    </Col>
                    <Col span={8}>
                    </Col>
                    <Col span={24}>
                        <Title level={3}>
                            Danh sách biến thể đã thêm
                        </Title>
                        <div style={{ textAlign: "right", marginBottom: "10px" }}>
                            <Button type="primary" size="small" onClick={() => this.handleSaveSortVariant()}><UpSquareOutlined /> Lưu sắp xếp </Button>
                        </div>
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                className='tableProductType'
                                rowKey={record => record.id}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                components={this.components}
                                onRow={(record, index) => ({
                                    index,
                                    moveRow: this.moveRow,
                                })}
                            />
                        </DndProvider>

                    </Col>
                </Row>
            </Card>
        );
    }
}

export default AddProductType;