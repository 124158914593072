
module.exports = {
    table: {
        display: "none"
    },
    trTop: {
        height: 30
       
    },
    trMain: {},
    th: {
        textAlign: 'center', 
        border: "1px solid #333", 
        paddingBottom:10,
        paddingTop:10, 
        paddingRight:15, 
        paddingLeft:15,
        fontSize: 16
    },
    td: {
        textAlign: 'left', 
    },


}