import React, { useState, useEffect } from 'react'
import { Input, Button, Form, Modal, Checkbox, Card, Row, Col, message } from 'antd'
import callApi from '../../utils/callApi'
import './ListUser.css'
const { TextArea } = Input;


function AddGoldTypeUser(props) {
    const [dataResource, setDataResource] = useState([]);
    const [userId, setUserId] = useState(null);
    const [types, setTypes] = useState([]);
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [user, setUser] = useState({ username: null, fullname: null, password: null, description: null });

    useEffect(() => {
        if (props.match.params.id) {
            setUserId(props.match.params.id)
            callApiGetTypeUser(props.match.params.id)
            callApiGetUser(props.match.params.id)
        }
    }, []);

    const callApiGetTypeUser = async (id) => {
        const response = await callApi(`v1/user/${id}/gold-type`, 'GET', {});
        if (response && response.status) {
            const useRole = [];
            const selected = [];
            response.data.map(item => {
                useRole.push({
                    label: `[${item.code}] ${item.title}`, value: item.code,
                })
                if (item.is_selected) {
                    selected.push(item.code)
                }
            })
            setTypes([...useRole])
            setSelectedCodes([...selected])
            return
        }
        message.error(response.msg);
    }

    const callApiGetUser = async (id) => {
        const response = await callApi(`v1/user/${id}`, 'GET', {});
        if (response && response.status) {
            setUser({ ...response.data })
            return
        }
        message.error(response.msg);
    }

    const callApiUpdateTypeUser = async () => {
        const response = await callApi(`v1/user/${userId}/gold-type`, 'POST', { type_codes: selectedCodes });
        if (response && response.status) {
            message.success(response.msg);
            return
        }
        message.error(response.msg);
    }

    const onChangeCheckbox = (checkedValues) => {
        setSelectedCodes([...checkedValues])
    }

    const handleChangeInput = async (event) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
    }

    return (
        <div className='create-user'>
            <Card>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: 30 }}>
                        <span style={{ fontSize: 24, fontWeight: 600 }}> Gán mã vàng quản lý tài khoản: {user.username} </span>
                    </Col>
                    <Col span={20} offset={2}>
                        <div className='listRoles'>
                            <Checkbox.Group className='group-type-user' options={types} value={selectedCodes} onChange={onChangeCheckbox} />
                        </div>
                        <div style={{ textAlign: "center", marginTop: 20 }}>
                            <Button type="primary" size="small" onClick={() => callApiUpdateTypeUser()}> Cập nhật </Button>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default AddGoldTypeUser;