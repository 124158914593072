import React from 'react';
import { Link } from 'react-router-dom';


import {
    Card,
    Table,
    Row,
    Col,
    Input,
    Popconfirm,
    Pagination,
    Button,
    message,
    Select,
    Modal,
    Switch,
    DatePicker,
} from 'antd';
import callApi from "../../utils/callApi";
import moment from 'moment'



const { RangePicker } = DatePicker;
const { Option } = Select;
class ListUrl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
            visibleModal: false,
            error_msg: ""

        };
    }

    // Tai du lieu tu api
    callApiGetListUrl = async (data = {}) => {
        await this.setState({
            loading: true
        })
        const dataFilter = await callApi('v1/supplier/url', 'get', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            return this.setState({ list: dataFilter.data, loading: false })
        }
    }

    callApiUpadteCrawlUrl = async (data) => {
        const dataFilter = await callApi('v1/supplier/url/update-crawl', 'PUT', data);
        if (dataFilter && dataFilter.status) {
            // console.log('Save Local');
            message.success('Thành công', 3);
            this.refreshData();
            return;
        }
        message.error(dataFilter.msg, 3);
    }

    refreshData = async () => {
        var dataQuery = {}

        await this.callApiGetListUrl(dataQuery)
    }

    handleCancel = () => {
        this.setState({
            visibleModal: false
        })
    }

    showModalError = (record) => {
        this.setState({
            visibleModal: true,
            error_msg: record.error_msg
        })
    }

    handleChangeCrawl = async(record) => {
        let {is_crawl, cmd} = record;
        let update = {
            is_crawl: Number(!Boolean(is_crawl)),
            url_cmd: cmd
        }
        this.callApiUpadteCrawlUrl(update);
    }




    async componentDidMount() {
        // Query New
        await this.callApiGetListUrl()
    }



    render() {
        const { loading, list, error_msg, visibleModal } = this.state
        const columnsTable = [
            {
                title: 'Thông tin',
                width: '70%',
                key: "info",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div className='title'> {record.name} </div>
                            <div> URL:  <a href={record.url} target='_blank'> {record.url} </a>  </div>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                align: "center",
                width: '20%',
                key: "status",
                render: (text, record, index) => {
                    return (
                        <div>
                            <span key={"statusRequest " + record.is_error ? "error" : "active"}>
                                {record.is_error ? (
                                    <div>
                                        <div style={{ marginBottom: 10 }}> Thời gian lỗi: {moment.unix(record.error_time).format('H:m DD/MM/YYYY ')} </div>
                                        <Button danger size='small' onClick={this.showModalError.bind(this, record)}>Chi tiết lỗi</Button>
                                    </div>

                                ) : (
                                    <div>Đang hoạt động</div>
                                )}
                            </span>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái crawl',
                align: "center",
                width: '10%',
                key: "crawl",
                render: (text, record, index) => {
                    return (
                        <div>
                            <div style={{ marginBottom: 10 }}> <Switch checked={record.is_crawl} onChange={this.handleChangeCrawl.bind(this, record)} /> </div>
                        </div>
                    )
                }
            },


        ];
        return (
            <div className="order-list">
                <Card>
                    <span> Tổng số <b>{list.length}</b> bản ghi </span>

                    <Table loading={loading} rowKey="id" columns={columnsTable} dataSource={list} size="small" pagination={false} />
                    <Modal
                        title={'Chi tiết lỗi'}
                        visible={visibleModal}
                        onCancel={this.handleCancel}
                        footer={null}
                        className="manual-modal">
                        <div>
                            {error_msg}
                        </div>
                    </Modal>
                </Card>
            </div>
        );
    }
}


export default ListUrl;